import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MultiOrgDropDown from '../components/MultiOrg';
import Table from './FeedbackTable';
import {LoadingSkeletonGroupWise, LoadingSkeletonTableWise} from '../components/LoadingSkeleton';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    btnPadding :{
      marginRight: theme.spacing(0.5) - 5,
      marginBottom: theme.spacing(0.5) - 5,
      fontSize: "0.775rem",
      minHeight: "40px"
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar
    },
    content: {
      flexGrow: 80,
      padding: theme.spacing(3)
    }
  }));

  export default function FeedbackGrid(props) {
    const classes = useStyles();
    const {buttonArray,setTestData, dispatch,loading, setLoading, started, pending, buttonName, setButtonClicked, typeButton, setSelectedType, selectTypeButton,setExamId, examId, setModal,showModal, handleHome, handleIconCLick, menuTitle, setMenuTitle,selectedTab ,setSelectedTab, setCodeLanguages } = props;

    const [tableData, setTabledata] = React.useState({});
    const [role, setRole] = React.useState([]);
    const [selectedRole, setSelectedRole] = React.useState('');
    const [selectedButton, setSelectedButton] = React.useState('');
    
    const handleGroupClick = (item) => {
      setButtonClicked(item);
      
      if(buttonArray[selectTypeButton] !== undefined){
        setTabledata(buttonArray[selectTypeButton][item][Object.keys(buttonArray[selectTypeButton][item])[0]][0]);        
      }
    }

    const handleRoleClick = (item) => {
        setSelectedRole(item);
        
        if(buttonArray[selectTypeButton] !== undefined){
          setTabledata(buttonArray[selectTypeButton][buttonName][item][0]);
          
        }
      }

    React.useEffect(() => {
        setSelectedType((typeButton === 'Tests' ? 'TEST' : (typeButton === 'Practice' ? 'PRACTISE' : (typeButton === 'Survey' ? 'SURVEY' : (typeButton === 'Feedback' ? 'FEEDBACK' : (typeButton === 'Completed' ? 'COMPLETED' : (typeButton === 'Expired' ? 'EXPIRED' : typeButton === 'WorkFlow' ? 'WORKFLOW' : '')))))));
        setMenuTitle(['Feedback','FeedbackCompleted','FeedbackExpired'])
        if(buttonArray[selectTypeButton] !== undefined && buttonArray[selectTypeButton][Object.keys(buttonArray[selectTypeButton])[0]] !== undefined){
        setButtonClicked(Object.keys(buttonArray[selectTypeButton])[0]);
        setSelectedButton(buttonArray[selectTypeButton][Object.keys(buttonArray[selectTypeButton])[0]][Object.keys(buttonArray[selectTypeButton][Object.keys(buttonArray[selectTypeButton])[0]])[0]][0])
        setRole(buttonArray[selectTypeButton][Object.keys(buttonArray[selectTypeButton])[1]]);
        setSelectedRole(buttonArray[selectTypeButton][Object.keys(buttonArray[selectTypeButton])[1]][0])
        setTabledata(buttonArray[selectTypeButton][Object.keys(buttonArray[selectTypeButton])[0]][Object.keys(buttonArray[selectTypeButton][Object.keys(buttonArray[selectTypeButton])[0]])[0]][0])
        }else{
          setTabledata({})
          setButtonClicked({});
          setSelectedButton({})
          setRole({});
          setSelectedRole({}) 
        }
    }, [buttonArray[selectTypeButton]])
  
    return (
      <main className={classes.content}>
          <div className={classes.toolbar} />
          <MultiOrgDropDown selectedTab = {selectedTab} setSelectedTab = {setSelectedTab} isFeedback={true} handleHome={handleHome} typeButton={typeButton} handleIconCLick={handleIconCLick} menuTitle={menuTitle} />
            <br></br>
            {loading ? <LoadingSkeletonGroupWise />  :
            <Grid style={{ paddingBottom: "0px" }} item xs={12}>
                {
                    role.length > 0 ? role.map((item,index) => {
                        return (<Button key={`gButton${index}`} className={classes.btnPadding} 
                                variant="outlined" color={(selectedRole === item) ? "primary" : "default"} 
                                onClick={ ()=> handleRoleClick(item)}
                                >
                                  {item}
                                </Button>)
                      
                  }) : <></>
                }
            </Grid>}
            <Grid item xs={12}>
              {loading ? <LoadingSkeletonTableWise /> :
              <Table showModal={showModal} dispatch={dispatch} tableData={tableData} typeButton={typeButton} status='000' setModal={setModal} setTestData={setTestData} examId = {examId} setExamId={setExamId} buttonName={buttonName} setCodeLanguages={setCodeLanguages} />
              }
            </Grid>
      </main>
    );
  }