import React, {useEffect, useState} from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import converter from 'convert-seconds';

const useStyles = makeStyles(theme => ({
    root: {
      display: "flex"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    title: {
      flexGrow: 1,
    }
}));

let d = Date.now();

export default (props) => {
    const {showModel,examDetail, setTimeoutModelShow,setTimeoutModelOneMinuteShow, ImageAlertShowModal, AudioAlertShowModal, NoFaceAlertModal, NoMatchAlertModal, MultipleFaceAlertModal, isVideoUploading, isTimeUp, setIsTimeUp, isVideoInterviewTest, isRecordingDone} = props;

    const calculateTimeLeft = () => {
      //
      //const difference = +new Date(d + examDetail[0].time_remaining * 1000) - +new Date();
      //

      let timeLeft = {};
      //const
      if (examDetail[0].time_remaining > 0) {
        timeLeft = {

          hours: converter(examDetail[0].time_remaining).hours,
          minutes: converter(examDetail[0].time_remaining).minutes,
          seconds: converter(examDetail[0].time_remaining).seconds
        };
      //

      if(!isVideoUploading && !showModel && !ImageAlertShowModal && !AudioAlertShowModal && !NoFaceAlertModal && !NoMatchAlertModal && !MultipleFaceAlertModal){
        examDetail[0].time_remaining  = examDetail[0].time_remaining -0.50;
      }


        if(examDetail[0].time_remaining === 60){
          setTimeoutModelOneMinuteShow(true);
        }
        examDetail[0].time_left = examDetail[0].time_remaining;

        if(timeLeft.hours === '00' && timeLeft.minutes === '00' && timeLeft.seconds === '00'){
          setTimeoutModelShow(true);
        }
        if (timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0) {
          setIsTimeUp(true);
      }

      }

      return timeLeft;
    };
    const calculateTimeLeftOnLoad = () => {
      //
      //const difference = +new Date(d + examDetail[0].time_remaining * 1000) - +new Date();
      //

      let timeLeft = {};
      //const
      if (examDetail[0].time_remaining > 0) {
        timeLeft = {

          hours: converter(examDetail[0].time_remaining).hours,
          minutes: converter(examDetail[0].time_remaining).minutes,
          seconds: converter(examDetail[0].time_remaining).seconds
        };
      //
       if(examDetail[0].time_remaining === 60){
          setTimeoutModelOneMinuteShow(true);
        }
        examDetail[0].time_left = examDetail[0].time_remaining;

        if(timeLeft.hours === '00' && timeLeft.minutes === '00' && timeLeft.seconds === '00'){
          setTimeoutModelShow(true);
        }
        if (timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0) {
          setIsTimeUp(true);
      }
      }

      return timeLeft;
    };
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeftOnLoad());
    const [timeSpent,setTimeSpent] = useState(0)
    useEffect(() => {
      let timer;
      if(!isVideoUploading) {
        timer = setTimeout(() => {
          setTimeLeft(calculateTimeLeft());
          setTimeSpent((timeSpent + 1)/2)
          localStorage.setItem('remainingTime',examDetail[0].time_remaining - timeSpent)
        }, 1000);
      }
      return () => clearTimeout(timer);
    },[timeLeft, isVideoUploading]);

    useEffect(() => {
      if (isVideoInterviewTest && isTimeUp &&  isRecordingDone &&!isVideoUploading) {
        setTimeoutModelShow(true);
        setTimeoutModelOneMinuteShow(true);
      }
    }, [isVideoInterviewTest, isTimeUp, isVideoUploading, isRecordingDone]);

    const timerComponents = [];

    Object.keys(timeLeft).forEach(interval => {
      if (!timeLeft[interval]) {
        return;
      }

      timerComponents.push(
        <span>
          {
            `${timeLeft['hours'] == 0 ? '00' : timeLeft['hours'].toString().length == 2 ? timeLeft['hours'] : '0'+timeLeft['hours']} : ${timeLeft['minutes'] == 0 ? '00' : timeLeft['minutes'].toString().length == 2 ? timeLeft['minutes'] : '0'+timeLeft['minutes']} : ${timeLeft['seconds'] == 0 ? '00' : timeLeft['seconds'].toString().length == 2 ? timeLeft['seconds'] : '0'+timeLeft['seconds']} `
          }
          {/* {timeLeft[interval]} {interval!=='seconds'?" : ":""} */}
        </span>
      );
    });

    return (
      <>
        {timerComponents.length ? timerComponents[0] : (
          <>
            <span>Time's up!</span>
              <>
                {setTimeoutModelShow(true)}
                {setTimeoutModelOneMinuteShow(true)}
              </>
          </>
        )}
      </>
    );
  }