import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Face, VpnKey} from "@material-ui/icons";
import axios from "../../../utils/axiosConfig";
import { useSelector } from 'react-redux'
import { getOrgId } from '../../../reducer/dashboardSlice';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ScrollableTabsButtonPrevent(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [errorMsg, setError] = React.useState("");
  const [password, setPassword] = React.useState(""); 
  const [confPassword, setConfPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [oldPassword, setOldPassword] = React.useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const validateField = (email, mobileNo) => {
    let status = {flag: false}, msg = '';
    let emailStatus = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    //msg += !emailStatus ? 'Invalid Email Address' : '';
    let mobileStatus = /^\d{10}$/.test(mobileNo);
    msg = (!emailStatus && !mobileStatus) ? 'Invalid Email Address, Invalid Mobile Number' : (!emailStatus ? 'Invalid Email Address' : (!mobileStatus ? 'Invalid Mobile Number' : ''));
    status.flag = (!emailStatus || !mobileStatus) ? true : false;
    status.msg = msg;
    return status;
  }
  const orgId = useSelector(getOrgId)
  const handleSubmit = (event) =>  {
    //alert('An essay was submitted: ' + this.state.value);
    event.preventDefault();
    setPasswordError('');
    
    let status = validateField( props.email, props.contactNo);
    

    if(status.flag){
        setError(status.msg);
    }else{
        axios.post('/dashboard/updateProfile',{
            headers: {
              'Access-Control-Allow-Origin': '*',
            },
            "firstName": props.firstName,
            "lastName": props.lastName,
            "contactNumber": props.contactNo,
            "userInfoID": props.userInfoId,
            "email": props.email,
            "orgId": orgId,
        }).then(profileData => {
            
            setError('User data updated successfully');
        }).catch(error=>{
            
            setError('Something went wrong, Please try again later...');
        })
    }
    
  }

  const changePassword = (event) => {
      event.preventDefault();
      setError('');
      
      if(password === confPassword){
          let flag = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password);
          if(!flag){
            setPasswordError('Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character');
          }else{
            setPasswordError('');
            axios.post('/dashboard/updatePassword',{
                headers: {
                  'Access-Control-Allow-Origin': '*',
                }, "oldPassword": oldPassword,
	            "newPassword": password
            }).then(updateData => {
                
                if(!updateData.data.oldMatch){
                    setError('Old password did not match...')
                }else{
                    if(!updateData.data.update){
                        setError('Something went wrong, Please try again later...');
                    }else{
                        setError('User data updated successfully');
                    }
                }

            }).catch(error=>{
                
                setError('Something went wrong, Please try again later...');
            })
          }
      }else{
        setPasswordError('Password does not match')
      }
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          //variant="scrollable"
          //scrollButtons="off"
          aria-label="scrollable prevent tabs example"
          
          centered
        >
          <Tab icon={<Face />} aria-label="profile" {...a11yProps(0)} />
          <Tab icon={<VpnKey />} aria-label="password" {...a11yProps(1)} />
          
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
      <form className={classes.form} onSubmit={handleSubmit} noValidate>
                <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                autoComplete="firstName"
                value={props.firstName}
                autoFocus
                onChange= {(event) => props.setFirstName(event.target.value)}
                />
                <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lastName"
                value={props.lastName}
                autoFocus
                onChange= {(event) => props.setLastName(event.target.value)}
                />
                <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="phone"
                label="Contact No"
                name="phone"
                type="tel"
                //pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                value={props.contactNo}
                autoComplete="phone"
                autoFocus
                onChange= {(event) => props.setContactNo(event.target.value)}
                />
                <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="email"
                label="Email Address"
                type="email"
                id="email"
                value={props.email}
                autoComplete="email"
                
                />
                <br/>
                <br/>
                <Typography style={{ fontSize: "12px"}}>{errorMsg}</Typography>
                <br/> 
                <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                >
                Update
                </Button>
                
            </form>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <form className={classes.form} onSubmit={changePassword} noValidate>
        <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                required
                id="oldPassword"
                label="Old Password"
                name="oldPassword"
                autoComplete="oldPassword"
                type="password"
                autoFocus
                onChange= {(event) => setOldPassword(event.target.value)}
                />
        <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                required
                id="password"
                label="Password"
                name="password"
                autoComplete="password"
                type="password"
                autoFocus
                onChange= {(event) => setPassword(event.target.value)}
                />
                <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="confPassword"
                label="Confirm Password"
                name="confPassword"
                autoComplete="confPassword"
                type="password"
                autoFocus
                onChange= {(event) => setConfPassword(event.target.value)}
                />
                <br />
                <Typography style={{ fontSize: "12px"}}>{passwordError}</Typography>
                <br />
                <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                >
                Change Password
                </Button>
        </form>
      </TabPanel>
      
    </div>
  );
}
