import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import axios from '../../utils/axiosConfig';
const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius : "15px"
    
  }
}));

export default (props) => {
  const classes = useStyles();
  const { testData, setQuestionNo, questionNo, setQuestionChange, navFlag } = props;

  const questionClicked = (questionNumber) => {

    setQuestionNo(questionNumber);
    setQuestionChange(true);
    //setQuestion()
  }
  const [anchorEl, setAnchorEl] = React.useState("false");
  const [issueDescription, setIssueDescription] = React.useState('');
  const [selectedValue, setSelectedValue] = React.useState('');

  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChangeRadio,
    value: item,
    name: 'color-radio-button',
    inputProps: { 'aria-label': item },
  });

  const handleClick = () => {
    setAnchorEl("true");
    //   document.getElementById("main-layout").style.background = '#dddddd'
    //   document.getElementById("main-layout").style.display= 'block'
    document.getElementById("main-body").style.overflow = 'hidden'

  };

  const handleClose = () => {
    setAnchorEl("false");
    //   document.getElementById("main-layout").style.background = ''
    //   document.getElementById("main-layout").style.display= ''
    document.getElementById("main-body").style.overflow = ''
    setSelectedValue('')
    setIssueDescription('')
  };

  const handleChange = (e) => {
    setIssueDescription(e.target.value);
  }

  const handleCancel = (e) => {
    setIssueDescription('');
    setSelectedValue('');
  }

  const reportIssue = () => {
    axios.post('/test/questionIssues', {
      headers: {
        'Access-Control-Allow-Origin': '*',
      }, qid: testData[questionNo - 1].qid, issuetype: selectedValue, description: issueDescription
    }).then(resp => {

    }).catch(err => {
      console.log('questionIssues error:', err);
    })
    setAnchorEl("false");
    document.getElementById("main-body").style.overflow = ''
    setIssueDescription('');
    setSelectedValue('');
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      {
        anchorEl === "false" ?
          <div className="flag-text">
            <a disabled={!navFlag} style={{ color: "#6D75D8" }} onClick={handleClick}><p>Flag this question</p></a>
          </div>
          : <div className="flag-text">
            <a disabled={!navFlag} style={{ color: "#6D75D8" }} onClick={handleClose}><p>Flag this question</p></a>
          </div>
      }

      {
        anchorEl === "true" ?
          <Modal
            disableEnforceFocus = {true}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={anchorEl}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            PaperProps={{
              style: {
                backgroundColor: "#e5e5e9"
              }
            }}
          >
            <Fade in={anchorEl}>
              {/* <div className={classes.paper}> */}
              <div className={classes.paper}>
                <div className='row' style={{ display: 'flex' }} ><h2 style={{ color: "#b45e9b" }}>Report Issue</h2>
                  <h2 style={{ color: "#b45e9b", marginLeft: "auto", cursor: "pointer" }} onClick={(e) => handleClose(e)}>x</h2></div>
                <div className="card-body row">

                  <div className="todo-list col-md-6">
                    <h4>Please select issue type from below option</h4>
                    <div className="todo-item">
                      <div className="checker"><span className=""><input type="radio" value='1' name='Grammatical or Pharasing Error' {...controlProps('Grammatical or Pharasing Error')} /></span></div>
                      <span>&nbsp;&nbsp;Grammatical or Pharasing Error</span>
                      <a href="javascript:void(0);" className="float-right remove-todo-item"><i className="icon-close"></i></a>
                    </div>
                    <div className="todo-item">
                      <div className="checker"><span className=""><input type="radio" value='2' name='Two or more correct answer' {...controlProps('Two or more correct answer')} /></span></div>
                      <span>&nbsp;&nbsp;Two or more correct answer</span>
                      <a href="javascript:void(0);" className="float-right remove-todo-item"><i className="icon-close"></i></a>
                    </div>

                    <div className="todo-item">
                      <div className="checker"><span className=""><input type="radio" {...controlProps('No correct answer')} /></span></div>
                      <span>&nbsp;&nbsp;No correct answer</span>
                      <a href="javascript:void(0);" className="float-right remove-todo-item"><i className="icon-close"></i></a>
                    </div>
                    <div className="todo-item">
                      <div className="checker"><span><input type="radio" {...controlProps('Outdated Question')} /></span></div>
                      <span>&nbsp;&nbsp;Outdated Question</span>
                      <a href="javascript:void(0);" className="float-right remove-todo-item"><i className="icon-close"></i></a>
                    </div>
                    <div className="todo-item">
                      <div className="checker"><span><input type="radio" {...controlProps('Irrelevent Question')} /></span></div>
                      <span>&nbsp;&nbsp;Irrelevent Question</span>
                      <a href="javascript:void(0);" className="float-right remove-todo-item"><i className="icon-close"></i></a>
                    </div><div className="todo-item">
                      <div className="checker"><span><input type="radio" {...controlProps('Other')} /></span></div>
                      <span>&nbsp;&nbsp;Other</span>
                      <a href="javascript:void(0);" className="float-right remove-todo-item"><i className="icon-close"></i></a>
                    </div>
                  </div>
                  <div className="todo-list col-md-6">
                    <h4>Please give additional detail about the issue </h4>
                    <div className="form-group">
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows="10" value={issueDescription} onChange={(e) => handleChange(e)}></textarea>
                    </div>
                    <div className="form-group">
                      <ul className="list-inline btn-list">
                        <li><a className="btn btn-blue" disabled={selectedValue === '' || issueDescription === ''} onClick={(e) => reportIssue(e)} >
                          <strong>Report Issue</strong>
                        </a></li>
                        <li>
                          <a className="btn" style={{color:'#000', background:'#dbdbdb', borderColor:'#dbdbdb'}} {...controlProps('')} onClick={(e) => handleCancel(e)} >
                            <strong>Reset</strong>
                          </a></li>
                        {/* <li><a className="btn btn-blue" {...controlProps('')} onClick={handleClose} >
                          <strong>Close</strong>
                        </a></li> */}
                      </ul></div>
                  </div>
                </div>

              </div>
            </Fade>
          </Modal> : <></>}

    </>
  );
}