import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

//import RouteWithLayout from './components/RouteWithComponent';


import SignIn from './page/Signin';
import Dashboard from './page/Dashboard';
import Profile from './page/Profile';
import Test from './page/Test';
import SignOut from './page/Signout';
import Cookies from 'js-cookie';

const Routes = (props) => {
  const [userExist, setUser] = React.useState(false);
  return (
    <Switch>
      {/* <Redirect
        exact
        from="/v2/user"
        to="/v2/user/login"
      /> */}
      <Route
        exact path='/v2/user/dashboard'
        // render={() => <Dashboard themeType={props.themeType} changeTheme={props.changeTheme} />}
        render={() => 
          (
            Cookies.get("sessionTime") && Cookies.get("sessionTime") == '0' ? (
              <Dashboard themeType={props.themeType} changeTheme={props.changeTheme} />
            ) :(
              <Redirect to="/v2/user/login" />
            ))
          }
      />
      <Route
        exact path='/v2/user/profile'
        // render={() => <Profile themeType={props.themeType} changeTheme={props.changeTheme} />}
        render={() => (
          Cookies.get("sessionTime") && Cookies.get("sessionTime") == '0' ? (
            <Profile themeType={props.themeType} changeTheme={props.changeTheme} />
          ) :(
            <Redirect to="/v2/user/dashboard" />
          )
        )}
      />
      <Route exact path='/v2/user/logout' render ={()=>(
        
                // userExist ? (
                  <SignOut setUser={setUser} userExist={userExist} />
                // ) :(
                //   <Redirect to="/v2/user/" />
                // )
              )} />
      <Route
        exact path='/v2/user/test'
        render={() => <Test themeType={props.themeType} changeTheme={props.changeTheme} />}
      />
      <Route exact path='/v2/user/' render ={()=>(
                Cookies.get("sessionTime") && Cookies.get("sessionTime") == '0' ? (
                  <Redirect to="/v2/user/dashboard" />
                ) :(
                  <SignIn setUser={setUser} userExist={userExist} />
                )
              )} />
      <Route exact path='/v2/user/login' render ={()=>(
        Cookies.get("sessionTime") && Cookies.get("sessionTime") == '0' ? (
          <Redirect to="/v2/user/dashboard" />
        ) :(
          <SignIn setUser={setUser} userExist={userExist} forceLogout={Cookies.get("sessionTime") && Cookies.get("sessionTime") == '2' && Cookies.get('token') == "" ? <>Session expired. Please login again!</> : ''} />
        )
      )} />
      {/* <Route path="/user/sign-in" component={SignIn} /> */}
      
      
      <Redirect from="/" to="/v2/user/dashboard" />
      
    </Switch>
  );
};

export default Routes;
