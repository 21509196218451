import * as React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector, useDispatch } from 'react-redux'
import { addUserHome, getLoading, setGroupDetails, getGroupDetails, setLoading, setOrgId, getOrgId, getTypeButton, setTypeButton,setUserHomeDetails ,getUserHomeDetails,setType , getType, setSelectedType, getSelectedType, setTestData, getTestData, setOrgData } from '../reducer/dashboardSlice';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

export default function MenuTabMobile(props) {
  const {handleHome, handleIconCLick, menuTitle,selectedTab,setSelectedTab} = props;
  const typeButton = useSelector(getTypeButton);
  const [value, setValue] = React.useState(selectedTab ? selectedTab : typeButton);
  const orgId = useSelector(getOrgId);
  const dispatch = useDispatch();
  let token = Cookies.get('token');
  useEffect(() => {
    setValue(selectedTab ? selectedTab : typeButton)
  })

  const handleChange = (newValue) => {
    setSelectedTab(newValue);
    setValue(newValue);
    let value = 0;
    if(newValue == 'Tests' || newValue == 'Feedback' || newValue == 'Survey'){
        value = 0;
    }else if(newValue == 'Completed' || newValue == 'FeedbackCompleted' || newValue == 'CompletedSurvey'){
        value = 1;
    }else{
        value = 2;
    }
    let data = {
        newValue: newValue,
        org : orgId,
        
    }  

    dispatch(addUserHome(data)).then((response)=>{
        // dispatch(setLoading())
        dispatch(setUserHomeDetails(response.payload))
        dispatch(setTypeButton(newValue))
        // dispatch(setGroupDetails(response.payload.groups))
        dispatch(setOrgId(response.payload.latestOrg))
        dispatch(setOrgData(response.payload.org))
        let typeData = response.payload;
        handleHome(typeData,newValue)
      }).catch((e)=>{
          console.log(e)
      })
  };

  return (
    <div>
        <FormControl variant="standard" style={{ width: "auto" }} >
        <InputLabel id="test-dropdown-input-label">Tests</InputLabel>
        <Select
          labelId="test-dropdown-label"
          id="test-dropdown"
          value={value}
          onChange={(e)=>handleChange(e.target.value)}
          label="Tests"
          style = {{textTransform: 'uppercase',color: '#6f2981', fontSize:'14px'}}
        >
          <MenuItem value={menuTitle[0]}>{menuTitle[0] === 'Tests' ? "Pending Test" : menuTitle[0] === 'Survey' ? "Pending Survey" : menuTitle[0] === 'WorkFlow' ? 'Pending WorkFlow' : "Pending Feedback"}</MenuItem>
          <MenuItem value={menuTitle[1]}>{menuTitle[1] === 'Completed' ? "Completed Test" : menuTitle[1] === 'SurveyCompleted' ? "Completed Survey" : menuTitle[1] === 'WorkFlowCompleted' ? 'Completed WorkFlow' : "Completed Feedback"}</MenuItem>
          <MenuItem value={menuTitle[2]}>{menuTitle[2] === 'Expired' ? "Expired Test" : menuTitle[2] === 'SurveyExpired' ? "Expired Survey" : menuTitle[2] === 'WorkFlowExpired' ? 'Expired WorkFlow' :"Expired Feedback"}</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}