import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { browserName, browserVersion } from "react-device-detect";
import SignalWifi3BarIcon from '@material-ui/icons/SignalWifi3Bar';
import BuildIcon from '@material-ui/icons/Build';
// import FlashOnIcon from '@material-ui/icons/FlashOn';
import LaptopWindowsIcon from '@material-ui/icons/LaptopWindows';
import LanguageIcon from '@material-ui/icons/Language';
import CancelIcon from '@material-ui/icons/Cancel';
import BeenhereIcon from '@material-ui/icons/Beenhere';
// import NetworkSpeed from '../machine_test'
import detectFlash from 'detect-flash';
import NetworkSpeed from 'network-speed';
import MultiOrg from '../components/MultiOrg';



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  btnPadding: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),

  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 80,
    padding: theme.spacing(3),
    // overflow:"scroll",
  },

}));

const span = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const div = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

export default (props) => {
  const classes = useStyles();
  const [internetSpeed, setInternateSpeed] = React.useState()
  const [osVersion, setOsversion] = React.useState('')
  React.useEffect(() => {
    const testNetworkSpeed = new NetworkSpeed();
    setInternateSpeed()
    getNetworkDownloadSpeed();
    async function getNetworkDownloadSpeed() {
      const baseUrl = "https://eu.httpbin.org/stream-bytes/50000";
      const fileSizeInBytes = 50000;
      const speed = await testNetworkSpeed.checkDownloadSpeed(baseUrl, fileSizeInBytes);
      const speedMbps = JSON.stringify(speed).split(',')[2].split('"')[3]
      setInternateSpeed(speedMbps)
    }
    function checkOsName() {
      var OSName = "Unknown";
      if (window.navigator.userAgent.indexOf("Windows NT 10.0") !== -1) OSName = "Windows 10";
      if (window.navigator.userAgent.indexOf("Windows NT 6.2") !== -1) OSName = "Windows 8";
      if (window.navigator.userAgent.indexOf("Windows NT 6.1") !== -1) OSName = "Windows 7";
      if (window.navigator.userAgent.indexOf("Windows NT 6.0") !== -1) OSName = "Windows Vista";
      if (window.navigator.userAgent.indexOf("Windows NT 5.1") !== -1) OSName = "Windows XP";
      if (window.navigator.userAgent.indexOf("Windows NT 5.0") !== -1) OSName = "Windows 2000";
      if (window.navigator.userAgent.indexOf("Mac") !== -1) OSName = "Mac/iOS";
      if (window.navigator.userAgent.indexOf("X11") !== -1) OSName = "UNIX";
      if (window.navigator.userAgent.indexOf("Linux") !== -1) OSName = "Linux";
      setOsversion(OSName)

    }
    checkOsName();



  }, [])
  const { typeButton ,handleHome } = props;
  const [flashFlag, setFlashFlag] = React.useState(false);
  detectFlash('path-to-swf/FlashDetector.swf')
    .then(
      () => setFlashFlag(true),
      err => setFlashFlag(false)
    );



  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
        <div className = 'row'>
          <MultiOrg handleHome={handleHome} typeButton={typeButton} />
        </div>

      <div className="listing_div">
        <div className="list_head">
          <p>
            {
              ['Component', 'Requirement', 'Status', 'Remark'].map((item, index) => {
                return (<span className={index === 0 ? "component-s":index === 3 ? "list_action_3":"list_action_2"} key={index}>{item}</span>)
              })

            }
          </p>
        </div>





        <div className="list_name list_odd" key={`band`}>
          <div className="list_detail_2">
            <span className="component-s">
              <strong className="viewInMob">Component - </strong>
              <SignalWifi3BarIcon style={{ marginBottom: "-10px" }} />  Bandwidth
            </span>
            <span className="list_action_2" ><strong className="viewInMob">Requirement - </strong>0.3 Mbps (Min)</span>
            <span className="list_action_2" ><strong className="viewInMob">Status - </strong>{internetSpeed > 0.3 ? <span><BeenhereIcon style={{ marginBottom: "-10px" }} /> Passed ({internetSpeed} Mbps)</span> : <span>  <CancelIcon style={{ marginBottom: "-10px" }} /> Failed ({internetSpeed} Mbps)</span>}</span>
            <span  className="list_action_3" ><strong className="viewInMob">Remark - </strong>	{internetSpeed > 0.3 ? <span>Bandwidth is very good to support online test</span> : <span>Bandwidth is not good to support online test</span>}
            </span>
          </div>
        </div>

        <div className="list_name list_even" key={`javaS`}>
          <div className="list_detail_2"><span className="component-s">
            <strong className="viewInMob">Component - </strong>
            <BuildIcon style={{ marginBottom: "-10px" }} />  JavaScript
          </span>
            <span className="list_action_2" ><strong className="viewInMob">Requirement - </strong>Enabled</span>
            <span className="list_action_2" ><strong className="viewInMob">Status -  </strong> <BeenhereIcon style={{ marginBottom: "-10px" }} /> Passed</span>
            <span  className="list_action_3" ><strong className="viewInMob">Remark - </strong> Javascript Supported</span>
          </div>
        </div>

        {/* <div className="list_name list_odd"  key={`flash`}>
                       <div className="list_detail_2"><span className="list_action_2">
                       <strong className="viewInMob">Component - </strong>
                        <FlashOnIcon style={{marginBottom:"-10px"}} /> Flash Support
                        </span>
                        <span className="list_action_2" ><strong className="viewInMob">Requirement - </strong>Enabled</span>
                        <span className="list_action_2" ><strong className="viewInMob">Status - </strong>{flashFlag ?<span> <BeenhereIcon style={{marginBottom:"-10px"}} />Passed </span>: <span><CancelIcon style={{marginBottom:"-10px"}} /> Failed </span>} </span>
                        <span className="list_action_2" ><strong className="viewInMob">Remark - </strong>{flashFlag ? 'Good to Go' : <a href="http://get.adobe.com/flashplayer" target="_blank">Please install Flash</a>} </span>
                    </div>
                    </div> */}

        <div className="list_name list_odd" key={`oper`}>
          <div className="list_detail_2"><span className="component-s">
            <strong className="viewInMob">Component - </strong>
            <LaptopWindowsIcon style={{ marginBottom: "-10px" }} /> Operating System
          </span>
            <span className="list_action_2" ><strong className="viewInMob">Requirement - </strong>	Windows 7 and above | Linux | MAC OS</span>
            <span className="list_action_2" ><strong className="viewInMob">Status - </strong>{osVersion === "Windows 10" || osVersion === "Windows 8" || osVersion === "Windows 7" || osVersion === "Mac/iOS" || osVersion === "Linux" ? <span><BeenhereIcon style={{ marginBottom: "-10px" }} /> Passed ({osVersion})</span> : <span>  <CancelIcon style={{ marginBottom: "-10px" }} /> Failed ({osVersion})</span>}</span>
            <span  className="list_action_3" ><strong className="viewInMob">Remark - </strong>{osVersion === "Windows 10" || osVersion === "Windows 8" || osVersion === "Windows 7" || osVersion === "Mac/iOS" || osVersion === "Linux" ? <span>	Operating System supported</span> : <span>	Operating System Not supported</span>}</span>
          </div>
        </div>

        <div className="list_name list_even" key={`brow`}>
          <div className="list_detail_2"><span className="component-s">
            <strong className="viewInMob">Component - </strong>
            <LanguageIcon style={{ marginBottom: "-10px" }} /> Browser Support
          </span>
            <span className="list_action_2" ><strong className="viewInMob">Requirement - </strong>Chrome 55 | Mozilla 50 and above</span>
            <span className="list_action_2" ><strong className="viewInMob">Status - </strong>
              {browserName === 'Chrome' && browserVersion > 55 ? <span>
                <BeenhereIcon style={{ marginBottom: "-10px" }} /> Passed ({browserName} {browserVersion})</span> : browserName === 'mozilla' && browserVersion > 50 ? <span><BeenhereIcon style={{ marginBottom: "-10px" }} /> Passed ({browserName} {browserVersion})</span> : <span>  <CancelIcon style={{ marginBottom: "-10px" }} /> Failed ({browserName}{browserVersion})</span>}</span>
            <span  className="list_action_3" ><strong className="viewInMob">Remark - </strong>{browserName === 'Chrome' && browserVersion > 55 ? <span>Good to Go</span> : browserName === 'mozilla' && browserVersion > 50 ? <span>Good to Go</span> : <span>PLease update your browser</span>}</span>
          </div>
        </div>
      </div>

    </main>
  );
}

