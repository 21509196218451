import axios from "axios";
import Cookies from "js-cookie";
import { serverConstants, localhostConstants } from "./serverConstants";

const resultInstance = axios.create({
  baseURL: serverConstants.find((item) => window.location.href.includes(item.urlUniqueString))
    ? serverConstants.find((item) => window.location.href.includes(item.urlUniqueString)).resultBaseURL
    : localhostConstants.resultBaseURL,
});
resultInstance.interceptors.request.use(
  (request) => {
    request.headers.common["Authorization"] = `Bearer ${Cookies.get("token")}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
resultInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      Cookies.set("sessionTime", "2");
      Cookies.set("login", "", { path: "/" });
      Cookies.set("token", "", { path: "/" });
      window.location.href = "/v2/user/login";
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default resultInstance;
