import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import {
    Typography,  
    AppBar,
    Toolbar
  } from '@material-ui/core';

export default class RadialChart extends React.Component {
      
    constructor(props) {
      super(props);
      
      this.state = {
        options: {
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: 0,
              endAngle: 270,
              hollow: {
                margin: 5,
                size: '30%',
                background: 'transparent',
                image: undefined,
              },
              dataLabels: {
                name: {
                  show: false,

                },
                value: {
                  show: false,
                }
              }
            }
          },
          colors: this.props.themeType === 'dark' ? ['#cfd8dc', '#90a4ae', '#607d8b', '#455a64', '#263238']: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5', '#0f83a8'],
          labels: ['Openness', 'Conscientiousness', 'Extraversion', 'Agreeableness', 'Neuroticism'],
          legend: {
            show: true,
            floating: true,
            fontSize: '11px',
            position: 'left',
            offsetX: 140,
            offsetY: 10,
            labels: {
                useSeriesColors: true,
            },
            markers: {
                size: 0
            },
            formatter: function(seriesName, opts) {
                return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
            },
            itemMargin: {
                horizontal: 1,
            }
          },
          responsive: [{
              breakpoint: 480,
              options: {
                  legend: {
                      show: false
                  }
              }
          }]
        },
        series: [40,30,70,20,90], //40,30,70,20,90]props.big5]
      }
    }

    render() {
      const { classes } = this.props;
      return (
        <>
        <AppBar position="static">
        <Toolbar variant="">
          <Typography variant="h6" className={classes .grow} color="inherit">
          Big Five Live Chart
          </Typography>
        </Toolbar>
      </AppBar>
        <div id="chart">
          <ReactApexChart options={
          {plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: 0,
              endAngle: 270,
              hollow: {
                margin: 5,
                size: '30%',
                background: 'transparent',
                image: undefined,
              },
              dataLabels: {
                name: {
                  show: false,

                },
                value: {
                  show: false,
                }
              }
            }
          },
          colors: this.props.themeType === 'dark' ? ['#00acc1', '#26a69a', '#80cbc4', '#4dd0e1', '#b2ebf2']: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5', '#0f83a8'],
          labels: ['Openness', 'Conscientiousness', 'Extraversion', 'Agreeableness', 'Neuroticism'],
          legend: {
            show: true,
            floating: true,
            fontSize: '11px',
            position: 'left',
            offsetX: 140,
            offsetY: 10,
            labels: {
                useSeriesColors: true,
            },
            markers: {
                size: 0
            },
            formatter: function(seriesName, opts) {
                return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
            },
            itemMargin: {
                horizontal: 1,
            }
          },
          responsive: [{
              breakpoint: 480,
              options: {
                  legend: {
                      show: false
                  }
              }
          }]
        }} series={this.props.big5} type="radialBar" height="350" />
        </div>
        </>
      );
    }
  }
