import React, { useState,useRef } from "react";
import ReactDOM from "react-dom";
import styled from "@emotion/styled";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import type { Quote as QuoteType } from "../types";
// import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from "@material-ui/core/styles";
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import cache from 'memory-cache';
import { Popover } from '@material-ui/core';
import { WEB_URL } from "../../../utils/constants";
import onWindowReload from '../../../utils/reloadFunction';
import supportedFileExtensions from './supportedFileExtensions'
import { v1_question_images_link } from "./v1-links";
import { translationMissingMessage } from "./translationConstants";
// import {
//   List,
//   ListItem,
//   ListItemText,
//   ListItemIcon,
//   IconButton,
//   ListItemSecondaryAction
// } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: '0px 5px',
  },
}));
const initial = Array.from({ length: 5 }, (v, k) => k).map(k => {
  const custom = {
    id: `id-${k}`,
    content: `Quote ${k}`
  };

  return custom;
});

const grid = 8;
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const QuoteItem = styled.div`
  width: 200px;
  border: 1px solid grey;
  margin-bottom: ${grid}px;
  background-color: lightblue;
`;

function Quote({ quote, index }) {
  return (
    <Draggable draggableId={quote.id} index={index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <span style={{ paddingLeft: "10px", display:"grid" }} dangerouslySetInnerHTML={{ __html: quote.content }} />
        </div>

      )}
    </Draggable>
  );
}


const QuoteList = function QuoteList({ quotes,handleHieght,columnHeight }) {
  return quotes.map((quote, index) => (
    <div id={'columnright'} ref={(e)=>handleHieght(e,index,"right")} style={{paddingLeft: "0px", marginLeft: "0px",height:columnHeight[index] ? columnHeight[index].left > columnHeight[index].right ?`${columnHeight[index].left}rem`:`${columnHeight[index].right}rem` :"auto"}} className="mtc-list-group list-group col" key={index} >
      <div className="list-group-item"><Quote quote={quote} index={index} key={quote.id} /></div>
    </div>
  ));
};

export default (props) => {

  const classes = useStyles();
  const theme = useTheme();

  const { testData, questionNo, setClearFlag, clearFlag, examId, setCheckFlag, examDetail
    , answerStatus, setAnswerStatus, marksScored, setMarksScored, totalMarks, setTotalMarks,
    wrongAnswerCount, setWrongAnswerCount, disable, setDisable, setTestData,windowSwitchCount,setWindowSwitch } = props;
  const getItems = () => {
    //   {
    //     id: `item-${k}`,
    //     primary: `item ${k}`
    //     }

  }




  const [leftHeight, setLeftHeight] = React.useState("50px");
  const [rightHeight, setRighttHeight] = React.useState("50px");

  //  const [leftHeight,setLeftHeight] = React.useState(document.getElementById('columnleft').clientHeight);
  //  const [rightHeight,setRighttHeight] = React.useState(document.getElementById('columnright').clientHeight);

  const [state, setState] = useState({ quotes: [] });
  const [items, setItems] = React.useState([]);
  const [header, setHeader] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [columnHeight,setColumnHeight] = React.useState([])
  // const [questionArr, setQuestionArr] = React.useState([]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  onWindowReload(examId,examDetail,setWindowSwitch,setTestData,windowSwitchCount,testData)

  const open = Boolean(anchorEl);

  const ref = useRef(null);
  const handleHieght = (e,index,col) => {
    let height = e ? e.offsetHeight * 0.0625 :0
    if(col === 'left'){
      columnHeight[index] = {left:height,right:columnHeight[index] ? columnHeight[index].right :""}
    }else{
      columnHeight[index] = {right:height,left:columnHeight[index] ? columnHeight[index].left:""}
    }

// setColumnHeight((pre)=>{
//   return[...pre]
// })
  }
  React.useEffect(() => {
    setColumnHeight([])
    if (testData[questionNo - 1].selectedChoiceAnswerId === undefined) {
      testData[questionNo - 1].selectedChoiceAnswerId = null
    }
    if (questionNo === testData.length) {


      setDisable(true);
    }
    let question = [], options = [], correctid = [];
    let selectedChoiceIdElem = "";
    // if(clearFlag){
    //   testData[questionNo -1].selectedChoiceId = "";
    //   setState({ quotes: [] });
    //   let temp = answerStatus;
    //   //temp[questionNo -1] = 0;
    //   setAnswerStatus(temp);
    //   testData[questionNo -1].buttonStyle = 'Not Answered';
    //   
    //   cache.put(examId, {testData, examDetail});
    //   window.localStorage.setItem(examId, {testData, examDetail});
    //   
    //   setClearFlag(false);
    // }
    for (let i = 0; i < testData[questionNo - 1].chkey.split("|^^|").length; i++) {

      let chkeyElem = testData[questionNo - 1].chkey.split("|^^|")[i];
      let correctElem = testData[questionNo - 1].correctid.split("|^^|")[i];
      let choiceTextElem = testData[questionNo - 1].choicetext.split("|^^|")[i];
      let choiceIdElem = testData[questionNo - 1].choiceid.split("|^^|")[i];
      let chresourcepath = testData[questionNo - 1].chresourcepath.split('|^^|')[i];
      // if (chkeyElem.includes('A') || chkeyElem.includes('Script')) {
     if( correctElem.split('_')[0]){
        question.push(choiceTextElem + '|' + chkeyElem.split('_')[1] + '|' + choiceIdElem + '|' + chresourcepath);
        correctid.push(chkeyElem.split('_')[1] + '|' + correctElem.split('_'[1]));
      }

      // else if (chkeyElem.includes('B') || chkeyElem.includes('Responses') || chkeyElem.includes('Resolution Query Stage')) {
        else if (correctElem.split('_'[1])) { 
      // else{
        options.push(choiceTextElem + '|' + chkeyElem.split('_')[1] + '|' + choiceIdElem + '|' + chresourcepath); //
      }
    }

    if (testData[questionNo - 1].selectedChoiceId !== null && testData[questionNo - 1].selectedChoiceId !== '' && testData[questionNo - 1].selectedChoiceId !== 'null') {
      options = [];
      for (let i = 0; i < testData[questionNo - 1].selectedChoiceId.split("|^^|").length; i++) {

        selectedChoiceIdElem = (testData[questionNo - 1].selectedChoiceId.split("|^^|")[i]);
        options.push(selectedChoiceIdElem); //
      }
    }


    let tempArray = [], optionArray = [];
    for (let i = 0; i < question.length; i++) {
      let questionElem = question[i];
      let optionsElem = options[i];
      let correctidElem = correctid[i];



      tempArray.push({ index: questionElem.split('|')[2], primary: questionElem.split('|')[0], image: questionElem.split('|')[3] });

      //  if(testData[questionNo -1].selectedChoiceId){
      //   optionArray.push({id: optionsElem.split('|')[1], content: optionsElem.split('|')[0]});

      //  }else{
      //  if(optionsElem !== undefined){
      optionArray.push({ id: optionsElem.split('|')[2], content: optionsElem.split('|')[0] });
      // }
      //  }
    }
    setHeader(tempArray);
    if (!(testData[questionNo - 1].selectedChoiceId !== null && testData[questionNo - 1].selectedChoiceId !== '' && testData[questionNo - 1].selectedChoiceId !== 'null')) {
      let i = optionArray.length,
        j = 0,
        temp;
      while (i--) {
        j = Math.floor(Math.random() * (i + 1));
        temp = optionArray[i];
        optionArray[i] = optionArray[j];
        optionArray[j] = temp;
      }
    }
    setState({ quotes: optionArray });

    let collectData = {quesData: testData, examDetail: examDetail}
    let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
    window.localStorage.setItem(examId, encode);
  }, [questionNo])

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    if (leftHeight > rightHeight) {
      setRighttHeight(document.getElementById('columnleft').style.height = { leftHeight });
    } else {
      setLeftHeight(document.getElementById('columnleft').style.height = { rightHeight });
    }

    const quotes = reorder(
      state.quotes,
      result.source.index,
      result.destination.index
    );
    testData[questionNo - 1].selectedChoiceId = "";
    testData[questionNo - 1].selectedChoiceAnswerId = "";
    for (let k = 0; k < quotes.length; k++) {
      testData[questionNo - 1].selectedChoiceId += quotes[k].content + '|' + k + '|' + quotes[k].id + '|^^|';
      testData[questionNo - 1].selectedChoiceAnswerId += header[k].index + "_" + quotes[k].id + '|^^|';
    }
    testData[questionNo - 1].selectedChoiceId = testData[questionNo - 1].selectedChoiceId.replace('null', '');
    testData[questionNo - 1].selectedChoiceId = testData[questionNo - 1].selectedChoiceId.substring(0, testData[questionNo - 1].selectedChoiceId.lastIndexOf('|^^|'));


    setState({ quotes });
    setCheckFlag(true);
    let collectData = {quesData: testData, examDetail: examDetail}
    let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
    window.localStorage.setItem(examId, encode);
  }

  return (
    <>
      {/* <strong> Question No {questionNo}</strong>< br/>  */}
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <span className="question-num">Q.{questionNo} of {testData.length}</span>
      </Typography>
      {/* <Button> Question No {questionNo}</Button>< br/>  */}
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        // PaperProps={{
        //   style: { height: '4%' ,textAlign:"center"},
        // }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{testData[questionNo - 1].qid}</Typography>
      </Popover>
      {(examDetail[0].selectedLanguage == null || examDetail[0].selectedLanguage == "en-IN" || examDetail[0].include_english == true) || (examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && testData[questionNo - 1].translated_qtext == null) ? <span dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].qtext }} /> : null}
      {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_qtext == null) ? <><br /><br /></> : null}
      {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" ? <span dangerouslySetInnerHTML={{__html:testData[questionNo - 1].translated_qtext || translationMissingMessage }} style={{ fontSize: testData[questionNo - 1].translated_qtext ? "" : "10px" }} /> : null}
      <br></br>
      <br></br>
      {((testData[questionNo -1].qresourcepath.split('|^^|')[0] !== undefined && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== null && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== "") ||
        (testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "")) ? (
        <div>
          {supportedFileExtensions.image.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
            <img
              src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                testData[questionNo -1].question_resource_url.split('|^^|')[0]
                :
                v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
              }
              key={testData[questionNo -1].qid}
              style={{ maxWidth: "100%" }}
              alt=""
            />
            : supportedFileExtensions.audio.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
              <audio
                controls
                src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                  testData[questionNo -1].question_resource_url.split('|^^|')[0]
                  :
                  v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                }
                key={testData[questionNo -1].qid}
                style={{ maxWidth: "100%" }}
                alt=""
              />
              : supportedFileExtensions.video.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                <video
                  controls
                  src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                    testData[questionNo -1].question_resource_url.split('|^^|')[0]
                    :
                    v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                  }
                  key={testData[questionNo -1].qid}
                  style={{ maxWidth: "100%" }}
                  alt=""
                />
                : supportedFileExtensions.file.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                  <a style={{ color: "#337ab7", fontSize: "16px" }} href={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                    testData[questionNo -1].question_resource_url.split('|^^|')[0]
                    :
                    v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                  }
                    download>CLICK TO DOWNLOAD</a>
                  :
                  null
          }
          <br/><br />
        </div>
      ) : (
        <></>
      )}
      <span style={{ color: "red", fontSize: "small", fontWeight: 600, textDecorationLine: "underline" }}>(Note : Please click and reshuffle the value from "Column B" to match it with "Column A" value)</span>
      {/* -------- */}
      <div className="row">
        <DragDropContext onDragEnd={onDragEnd}>

          <div className="col-sm-6 col-xs-6 question-left" style={{ textAlign: "left" }}>
            <h4><b>Column A</b></h4>

            <Droppable droppableId="list" >
              {provided => (
                <ul ref={provided.innerRef} {...provided.droppableProps} className="mtc-column-list">
                  {header.map((item, index) => (

                    <div id={'columnleft'} ref={(e)=>handleHieght(e,index,"left")} key={index} className="mtc-list-group list-group col" style={{ paddingLeft: "0px", marginLeft: "0px",height:columnHeight[index] ? columnHeight[index].left > columnHeight[index].right ? `${columnHeight[index].left}rem`:`${columnHeight[index].right}rem` :"auto"}}>
                      <div className="list-group-item" style={{ border: "none" }} key={`header_${index}`}>
                        <span dangerouslySetInnerHTML={{ __html: item.primary }} />
                        <br></br>
                        {item.image !== "undefined" && item.image !== "" ? <img src={`${WEB_URL}ah-resources/questionimages/` + item.image}/> : null}
                      </div>
                    </div>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </div>
        </DragDropContext>


        <DragDropContext onDragEnd={onDragEnd}>
          <div className="col-sm-6 col-xs-6 answer-right" style={{ textAlign: "left" }}>
            <h4><b>Column B</b></h4>
            <Droppable droppableId="list">
              {provided => (
                <ul className="mtc-column-list">
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <QuoteList quotes={state.quotes} columnHeight={columnHeight} handleHieght={handleHieght} />
                    {provided.placeholder}
                  </div>
                </ul>
              )}
            </Droppable>
          </div>
        </DragDropContext>

      </div>

    </>
  );
}
