import React from 'react';
const Loader = (props) => {
    return (
        <>
            <div style={{background:"#fff"}} className="auth-wrapper">
                <div className="auth-content-loader"> 
                
                </div>
                <label>Loading...</label>
            </div>
        </>
    );
}
export default Loader;


  