import React, {useState} from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { createBrowserHistory } from 'history';
import Routes from './Routes';

import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import blueGrey from "@material-ui/core/colors/blueGrey";
import lightGreen from "@material-ui/core/colors/lightGreen";
import grey from "@material-ui/core/colors/grey";
import deepPurple from "@material-ui/core/colors/deepPurple";

import theme from './theme';

const browserHistory = createBrowserHistory();

function App() {
  const [themeType, setThemeType] = useState('light');

  function changeTheme(selectColor){
    if (selectColor == 'dark'){
      setThemeType('light');
    } else {
      setThemeType('dark');
    }
  }

  let theme = createMuiTheme({
    palette: {
      primary: {
        light:'#a057b1',
        main: '#6f2981',
        dark:'#400054'
        // light: deepPurple[300],
        // main: deepPurple[500], //lightGreen
        // //main: {background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'},
        // dark: deepPurple[700]
      },
      secondary: {
        // light: deepPurple[300], //blueGrey
        // main: deepPurple[500],
        // dark: deepPurple[700]
        light:'#a057b1',
        main: '#6f2981',
        dark:'#400054'
      },
      info:{
        light:'#ffffff',
        main:'#e5e6e9',
        dark: '#b3b4b7'
      },
      type: themeType
    },
    typography: {
      fontFamily: ['"Open Sans"','sans-serif' 
      ].join(','),},
  });


  return (
    
    <MuiThemeProvider  theme={theme}>
        <CssBaseline />
        <Router history={browserHistory}>
          <Routes themeType={themeType} changeTheme={changeTheme} />
        </Router>
      </MuiThemeProvider>
  );
}

export default App;
