import saveToMongoFunction from "./saveToMongoFunction";

const onFinishSaveToMongoFunction = async (examId, examDetail, setWindowSwitch, setTestData, windowSwitchCount, testData, statusOnFinish) => {
  if (statusOnFinish !== "998") {
    let response = await saveToMongoFunction(examId, examDetail, setWindowSwitch, setTestData, windowSwitchCount, testData, statusOnFinish);

    return response;
  } else {
    return true;
  }
};

export default onFinishSaveToMongoFunction;
