import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "./CompletedTable";
import MultiOrgDropDown from "./MultiOrg";
import { LoadingSkeletonGroupWise, LoadingSkeletonTableWise } from "../components/LoadingSkeleton";
import { useEffect } from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  btnPadding: {
    marginRight: theme.spacing(0.5) - 5,
    marginBottom: theme.spacing(0.5) - 5,
    fontSize: "0.775rem",
    minHeight: "40px",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 80,
    padding: theme.spacing(3),
    // overflow:"scroll",
  },
}));

export default function FullWidthGrid(props) {
  const classes = useStyles();
  const {
    loading,
    setLoading,
    dispatch,
    handleDownlaodReport,
    process,
    buttonArray,
    started,
    pending,
    buttonName,
    setButtonClicked,
    typeButton,
    setSelectedType,
    selectTypeButton,
    handleHome,
    handleIconCLick,
    menuTitle,
    setMenuTitle,
    selectedTab,
    setSelectedTab,
    searchRecord,
    setManualGenerate,
    manualGenerate,
    setCompleted,
  } = props;
  const [tableData, setTabledata] = React.useState({});

  const [groupIdData, setGroupIddata] = React.useState();
  const [reportAllowed, setReportAllowed] = React.useState(false);

  const handleGroupClick = (item) => {
    setButtonClicked(item);
    setReportAllowed(buttonArray[selectTypeButton][item].report);
    setGroupIddata(buttonArray[selectTypeButton][item].groupId);
    if (buttonArray[selectTypeButton] !== undefined) {
      setTabledata(buttonArray[selectTypeButton][item]);
    }
  };

  React.useEffect(() => {
    if (searchRecord != true) {
      if (typeButton === "FeedbackCompleted") {
        setMenuTitle(["Feedback", "FeedbackCompleted", "FeedbackExpired"]);
        setSelectedType("FEEDBACK_COMPLETED");
      } else if (typeButton === "SurveyCompleted") {
        setMenuTitle(["Survey", "SurveyCompleted", "SurveyExpired"]);
        setSelectedType("SURVEY_COMPLETED");
      } else if (typeButton === "WorkFlowCompleted") {
        setMenuTitle(["WorkFlow", "WorkFlowCompleted", "WorkFlowExpired"]);
        setSelectedType("WORKFLOW_COMPLETED");
      } else {
        setMenuTitle(["Tests", "Completed", "Expired"]);
        setSelectedType("COMPLETED");
      }
    }

    if (buttonArray[selectTypeButton] !== undefined && buttonArray[selectTypeButton][Object.keys(buttonArray[selectTypeButton])[0]] !== undefined) {
      setButtonClicked(Object.keys(buttonArray[selectTypeButton])[0]);
      setTabledata(buttonArray[selectTypeButton][Object.keys(buttonArray[selectTypeButton])[0]]);
      setReportAllowed(buttonArray[selectTypeButton][Object.keys(buttonArray[selectTypeButton])[0]].report);
      setGroupIddata(buttonArray[selectTypeButton][Object.keys(buttonArray[selectTypeButton])[0]].groupId);
    } else {
      setTabledata({});
    }
    // Need to check else condition.
  }, [buttonArray[selectTypeButton]]);

  return (
    <main className={searchRecord != true ? classes.content : `${classes.content} searchrecord-main`}>
      {searchRecord != true ? (
        <>
          <div className={classes.toolbar} />
          <div className="row">
            <MultiOrgDropDown
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              handleIconCLick={handleIconCLick}
              handleHome={handleHome}
              typeButton={typeButton}
              menuTitle={menuTitle}
            />
          </div>
          <br></br>
        </>
      ) : (
        ""
      )}
      {/* <Grid container spacing={3}> */}
      {loading ? (
        <LoadingSkeletonGroupWise />
      ) : (
        <Grid style={{ paddingBottom: "0px" }} item xs={12}>
          {buttonArray[selectTypeButton] !== undefined ? (
            Object.keys(buttonArray[selectTypeButton]).map((item, index) => {
              if (item !== "tableHeaders") {
                return (
                  <Button
                    key={`completed${index}`}
                    className={classes.btnPadding}
                    variant="outlined"
                    color={buttonName === item ? "primary" : "default"}
                    onClick={() => handleGroupClick(item)}
                  >
                    {item}
                  </Button>
                );
              }
            })
          ) : (
            <></>
          )}

          {/* <Button  className={classes.btnPadding} variant="outlined" color="primary">
                      Primary
                </Button> */}
        </Grid>
      )}

      <Grid item xs={12}>
        {/* <Paper className={classes.paper}>xs=12 sm=6</Paper> */}
        {loading ? (
          <LoadingSkeletonTableWise />
        ) : (
          <Table
            setCompleted={setCompleted}
            setManualGenerate={setManualGenerate}
            manualGenerate={manualGenerate}
            tableData={tableData}
            typeButton={typeButton}
            buttonName={buttonName}
            searchRecord={searchRecord}
          />
        )}
        {/* {tableHeader !== '' ?
            <Table tableData={tableData} tableheader={tableHeader} status='000' />
            :
            <></>
            } */}
      </Grid>
      {/* <Grid item xs={12} >
          <h2>Not Started</h2>

            <Table tableData={tableData} typeButton={typeButton} status='001' />
          </Grid> */}

      {/* </Grid> */}
      {searchRecord != true && reportAllowed ? (
        process ? (
          <a style={{ color: "#31AE14", cursor: "pointer", fontWeight: "bold" }} onClick={(e) => handleDownlaodReport({ groupId: groupIdData })}>
            Click here to download the Report
          </a>
        ) : (
          <span>Please wait, Your request is being processed.</span>
        )
      ) : (
        ""
      )}
    </main>
  );
}
