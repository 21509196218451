import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

// import Paper from '@material-ui/core/Paper';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from '@material-ui/core/Grid';
import clsx from "clsx";
import FormLabel from '@material-ui/core/FormLabel';
import { Popover, Typography } from '@material-ui/core';
import { WEB_URL } from '../../../utils/constants';
import onWindowReload from '../../../utils/reloadFunction';
import supportedFileExtensions from './supportedFileExtensions'
import { v1_question_images_link } from './v1-links';
import { translationMissingMessage } from './translationConstants';
const useStyles = makeStyles(theme => ({
  // root: {
  //   display: "flex"
  // },
  // paper: {
  //     padding: theme.spacing(2),
  //     color: theme.palette.text.secondary,
  // },
  // icon: {
  //     borderRadius: "50%",
  //     width: 16,
  //     height: 16,
  //     boxShadow:
  //       "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  //     backgroundColor: "#f5f8fa",
  //     backgroundImage:
  //       "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  //     "$root.Mui-focusVisible &": {
  //       outline: "2px auto rgba(19,124,189,.6)",
  //       outlineOffset: 2
  //     },
  //     "input:hover ~ &": {
  //       backgroundColor: "#ebf1f5"
  //     },
  //     "input:disabled ~ &": {
  //       boxShadow: "none",
  //       background: "rgba(206,217,224,.5)"
  //     }
  //   },
  //   checkedIcon: {
  //     backgroundColor: "#137cbd",
  //     backgroundImage:
  //       "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  //     "&:before": {
  //       display: "block",
  //       width: 16,
  //       height: 16,
  //       backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
  //       content: '""'
  //     },
  //     "input:hover ~ &": {
  //       backgroundColor: "#106ba3"
  //     }
  //   },
  //   popover: {
  //       pointerEvents: 'none',
  //     }
  root: {
    display: "flex",
    borderRadius: "15px",
    border: "2px solid #6f2981 ",
    width: "15px",
    height: "15px",
    position: "relative",
    visibility: "visible",
    top: "0px",
    left: "-15px",
    padding: "10px",
    textAlign: "justify",
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: '0px 5px',
  },
  icon: {
    display: 'none',
    borderRadius: "15px",
    border: "1px solid #6f2981 ",
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    background: "#fff",
    backgroundColor: "#fff",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    display: "flex",
    top: "-15px",
    left: "-6px",
    padding: "3px",
    borderRadius: "15px",
    background: "#6f2981",
    border: "2px solid #fff",
    // backgroundColor: "#137cbd",
    backgroundImage:
      "radial-gradient(#fff,#fff 28%,transparent 32%)",
    "&:before": {
      display: "block",
      width: "15px",
      height: "15px",
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
  },
}));



export default (props) => {
  const classes = useStyles();
  const { testData, questionNo, setClearFlag, clearFlag, examId, setCheckFlag, examDetail
    , answerStatus, setAnswerStatus, disable, setDisable ,setTestData,windowSwitchCount,setWindowSwitch} = props;

  const [selectAnswer, setAnswer] = React.useState(''); //testData[questionNo -1].choicetext.split('|^^|')[testData[questionNo -1].choiceid.split('|^^|').indexOf(testData[questionNo -1].selectedChoiceId)]

  const [tempJson, setTempJson] = React.useState({});


  const [value, setValue] = React.useState('');
  const [value1, setValue1] = React.useState('');
  const [value2, setValue2] = React.useState('');

  const [timeSpentInSec,setTimeSpentInSec] = React.useState(0)
  const [remainigTime,setRemainingTime] = React.useState(examDetail[0].time_remaining)
  React.useEffect(() => {
     setTimeout(() => {
      setTimeSpentInSec(timeSpentInSec + 1)
    }, 1000);
    setRemainingTime(examDetail[0].time_remaining - timeSpentInSec/2)
    //return () => clearTimeout(timer);
  });
  //  setValue(testData[questionNo -1].selectedChoiceId.split(',').length <= 3 ? 
  //  testData[questionNo -1].selectedChoiceId.split(',')[0] : '');
  //  setValue1(testData[questionNo -1].selectedChoiceId.split(',').length <= 2 ? 
  //  testData[questionNo -1].selectedChoiceId.split(',')[1] : '');
  //  setValue2(testData[questionNo -1].selectedChoiceId.split(',').length === 3 ? 
  //  testData[questionNo -1].selectedChoiceId.split(',')[2] : '');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  onWindowReload(examId,examDetail,setWindowSwitch,setTestData,windowSwitchCount,testData)

  const open = Boolean(anchorEl);


  const handleChange = (event) => {
    if (event && event.target.value) {
      let tval = event.target.value;
      setValue(tval);
      if (Object.keys(tempJson).length === 3) {
        testData[questionNo - 1].selectedChoiceId =  tval + ',' + value1 + ',' + value2
      } else if (Object.keys(tempJson).length === 2) {
        testData[questionNo - 1].selectedChoiceId =  tval + ',' + value1;
      } else {
        testData[questionNo - 1].selectedChoiceId =  tval;
      }
    } else {
      setValue('')
    }
    // setCheckFlag(true)
  };

  const handleChange1 =  (event) => {
    if (event && event.target.value) {
    let tval1 = event.target.value;
    setValue1(tval1);
    if (Object.keys(tempJson).length === 3) {
      testData[questionNo - 1].selectedChoiceId =  value + ',' + tval1 + ',' + value2
    } else if (Object.keys(tempJson).length === 2) {
      testData[questionNo - 1].selectedChoiceId =  value + ',' + tval1;
    } else {
      testData[questionNo - 1].selectedChoiceId =  value;
    }
  } else {
    setValue1('')
  }
    //  setCheckFlag(true)

  };

  const handleChange2 =  (event) => {
    let tval = event.target.value;
     setValue2(tval);
    if (Object.keys(tempJson).length === 3) {
      testData[questionNo - 1].selectedChoiceId =  value + ',' + value1 + ',' + tval
    } else if (Object.keys(tempJson).length === 2) {
      testData[questionNo - 1].selectedChoiceId =  value + ',' + value1;
      //  setCheckFlag(true);
    } else {
      testData[questionNo - 1].selectedChoiceId =  value;
    }

  };

  const getAnswer = (event) => {
    if(event && event.target.value !== undefined){
    // setClearFlag(false);
    // setCheckFlag(true);
    //setAnswer(event.target.value);
    setAnswer(testData[questionNo - 1].selectedChoiceId !== undefined ? testData[questionNo - 1].selectedChoiceId :"");
    let temp = answerStatus;
    //temp[questionNo -1] = (testData[questionNo -1].choiceid.split('|^^|')[testData[questionNo -1].choicetext.split('|^^|').indexOf(event.target.value)] === testData[questionNo -1].correctid) ? testData[questionNo -1].qmarks : 0;
    setAnswerStatus(temp);
    testData[questionNo - 1].answerStatus =
      Object.keys(tempJson).length === 3 ? (testData[questionNo - 1].choiceid.split('|^^|')[testData[questionNo - 1].choicetext.split('|^^|').indexOf(value)] + "," +
        testData[questionNo - 1].choiceid.split('|^^|')[testData[questionNo - 1].choicetext.split('|^^|').indexOf(value1)] + "," +
        testData[questionNo - 1].choiceid.split('|^^|')[testData[questionNo - 1].choicetext.split('|^^|').indexOf(value2)] === testData[questionNo - 1].correctid) ? true : false
        :
        Object.keys(tempJson).length === 2 ? (testData[questionNo - 1].choiceid.split('|^^|')[testData[questionNo - 1].choicetext.split('|^^|').indexOf(value)] + "," +
          testData[questionNo - 1].choiceid.split('|^^|')[testData[questionNo - 1].choicetext.split('|^^|').indexOf(value1)] === testData[questionNo - 1].correctid) ? true : false
          : (testData[questionNo - 1].choiceid.split('|^^|')[testData[questionNo - 1].choicetext.split('|^^|').indexOf(value)] === testData[questionNo - 1].correctid) ? true : false;

    testData[questionNo - 1].buttonStyle = 'Answered';
    let collectData = { quesData: testData, examDetail: examDetail }
    let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
    window.localStorage.setItem(examId, encode);
  }
  }


  // setAnswer(testData[questionNo -1].selectedChoiceId);

  React.useEffect(() => {
    // var strVal  = testData[questionNo -1].selectedChoiceId;
    // var result = strVal.split(",").map(x=>+x);
    if (questionNo === testData.length) {


      setDisable(true);
    }


    setValue(testData[questionNo - 1].selectedChoiceId.split(',').length <= 3 ?
      testData[questionNo - 1].selectedChoiceId.split(',')[0] : '');
    setValue1(testData[questionNo - 1].selectedChoiceId.split(',').length <= 2 ?
      testData[questionNo - 1].selectedChoiceId.split(',')[1] : '');
    setValue2(testData[questionNo - 1].selectedChoiceId.split(',').length === 3 ?
      testData[questionNo - 1].selectedChoiceId.split(',')[2] : '');
    setAnswer(testData[questionNo - 1].selectedChoiceId);
    let tempOption = [];
    let tempJson1 = {};
    
   
   let chkey = testData[questionNo - 1].chkey.split('|^^|')
   chkey.map((item, index) => {
      if (tempOption.indexOf(item) === -1) {
        tempOption.push(item);
        tempJson1[item] = [];
      }

      let tempJson1Item = testData[questionNo - 1].choiceid.split('|^^|')[index] + '|' +
        testData[questionNo - 1].choicetext.split('|^^|')[index] + '|' +
        testData[questionNo - 1].chresourcepath.split('|^^|')[index] + '|' +
        testData[questionNo - 1].choice_resource_url.split('|^^|')[index]         
      if (examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN") {
        tempJson1Item += '|' + testData[questionNo - 1].translated_choicetext.split('|^^|')[index]
      }
      tempJson1[item].push(tempJson1Item);
    })
    setTempJson(tempJson1);
    //  setAnswer(testData[questionNo -1].choicetext.split('|^^|')[testData[questionNo -1].choiceid.split('|^^|').indexOf(testData[questionNo -1].selectedChoiceId.split(',')[0])]+
    //  ','+testData[questionNo -1].choicetext.split('|^^|')[testData[questionNo -1].choiceid.split('|^^|').indexOf(testData[questionNo -1].selectedChoiceId.split(',')[1])])



    // setValue(testData[questionNo -1].selectedChoiceId.split(',').length <= 3 ? 
    //    testData[questionNo -1].selectedChoiceId.split(',')[0] : '');
    //    setValue1(testData[questionNo -1].selectedChoiceId.split(',').length <= 2 ? 
    //    testData[questionNo -1].selectedChoiceId.split(',')[1] : '');
    //    setValue2(testData[questionNo -1].selectedChoiceId.split(',').length === 3 ? 
    //    testData[questionNo -1].selectedChoiceId.split(',')[2] : '');

    if (Object.keys(tempJson).length === 3) {
      if (testData[questionNo - 1].selectedChoiceId.split(',').length === 3) {
        if ((value !== '' && value !== 'undefined' && value !== undefined) && (value1 !== '' && value1 !== 'undefined' && value1 !== undefined) && (value2 !== '' && value2 !== 'undefined' && value2 !== undefined)) {
          setCheckFlag(true);
        }
        else {
          setCheckFlag(false);
        }
      }
    }
    if (Object.keys(tempJson).length === 2) {
      if (testData[questionNo - 1].selectedChoiceId.split(',').length === 2) {
        if ((value !== '' && value !== 'undefined' && value !== undefined) && (value1 !== '' && value1 !== 'undefined' && value1 !== undefined)) {
          setCheckFlag(true);
        }
        else {
          setCheckFlag(false);
        }
      }
    }
    if (Object.keys(tempJson).length === 1) {
      if (testData[questionNo - 1].selectedChoiceId.split(',').length === 1) {
        if (value !== '' && value !== 'undefined' && value !== undefined) {
          setCheckFlag(true);
        }
        else {
          setCheckFlag(false);
        }
      }
    }

    if (clearFlag) {
      testData[questionNo - 1].selectedChoiceId = "";
      setAnswer("");
      setValue('');
      setValue1('');
      setValue2('');
      let temp = answerStatus;
      //temp[questionNo -1] = 0;
      setAnswerStatus(temp);
      testData[questionNo - 1].buttonStyle = 'Not Answered';
      let collectData = { quesData: testData, examDetail: examDetail }
      let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
      window.localStorage.setItem(examId, encode);
      setClearFlag(false);
    }
    let collectData = { quesData: testData, examDetail: examDetail }
    let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
    window.localStorage.setItem(examId, encode);

  }, [value,value1,value2,questionNo, disable, clearFlag, selectAnswer,testData])//,[selectAnswer]

  const StyledRadio = (props) => {
    const classes = useStyles();
    return (
      <Radio
        className={classes.root}
        // disableRipple
        color="default"
        checkedIcon={
          // Object.keys(tempJson)[index] ===  'BLANK1' ? value  == elem.split('|')[0] ?  <span className={clsx(classes.icon, classes.checkedIcon)} /> : <span className={classes.icon} />: 
          // Object.keys(tempJson)[index] ===  'BLANK2' ? value1  == elem.split('|')[0] ? <span className={clsx(classes.icon, classes.checkedIcon)} /> : <span className={classes.icon} />: value2  == elem.split('|')[0] ?  <span className={clsx(classes.icon, classes.checkedIcon)} /> : <span className={classes.icon} />
          
          testData[questionNo - 1].selectedChoiceId !== '' && testData[questionNo - 1].selectedChoiceId !== undefined ? <span className={clsx(classes.icon, classes.checkedIcon)} /> : <span className={classes.icon} />
        }
        icon={<span className={classes.icon} />}
        {...props}
        onClick={(e)=>getAnswer(e)}


      />
      // className={classes.root}
      // disableRipple
      // color="#fff"
      // checkedIcon={
      //   selectAnswer !== " " && selectAnswer !== undefined ? (
      //     <span className={clsx(classes.icon, classes.checkedIcon)} />
      //   ) : (
      //     <span className={classes.icon} />
      //   )
      // }
      // icon={<span className={classes.icon} />}
      // {...props}
      // // onClick={() => getAnswer(props.value)}
      // onClick={getAnswer}




    );
  }


  return (
    <>
      <Grid container spacing={3}>
        {examDetail[0].comprehensionflag && testData[questionNo - 1].passageheader
          ?
          <Grid item xs={6}>
            <span className="question-num">Q.{testData[questionNo - 1].passageQuestionNo}</span>
            {(examDetail[0].selectedLanguage == null || examDetail[0].selectedLanguage == "en-IN" || examDetail[0].include_english == true) || (examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && testData[questionNo - 1].translated_passageheader == null) ? <span dangerouslySetInnerHTML={{__html:testData[questionNo - 1].passageheader }}/> : null}
            {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_passageheader == null) ? <><br /><br /></> : null}
            {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" ? <span dangerouslySetInnerHTML={{__html:testData[questionNo - 1].translated_passageheader || translationMissingMessage }} style={{ fontSize: testData[questionNo - 1].translated_passageheader ? "" : "10px" }} /> : null}< br />
            {(examDetail[0].selectedLanguage == null || examDetail[0].selectedLanguage == "en-IN" || examDetail[0].include_english == true) || (examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && testData[questionNo - 1].translated_passagetext == null) ? <span  dangerouslySetInnerHTML={{__html: testData[questionNo -1].passagetext}} /> : null}
            {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_passagetext == null) ? <><br /><br /></> : null}
            {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" ? <span  dangerouslySetInnerHTML={{__html: testData[questionNo -1].translated_passagetext || translationMissingMessage}} style={{ fontSize: testData[questionNo - 1].translated_passagetext ? "" : "10px" }} /> : null}< br />
            {((testData[questionNo -1].passageImage !== undefined && testData[questionNo -1].passageImage !== null && testData[questionNo -1].passageImage !== "") ||
              (testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "")) ? (
              <div>
                <br />
                {supportedFileExtensions.image.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                  <img
                    src={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                      testData[questionNo -1].passageImageURL
                      :
                      v1_question_images_link + testData[questionNo -1].passageImage
                    }
                    key={testData[questionNo -1].qid}
                    style={{ maxWidth: "100%" }}
                    alt=""
                  />
                  : supportedFileExtensions.audio.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                    <audio
                      controls
                      src={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                        testData[questionNo -1].passageImageURL
                        :
                        v1_question_images_link + testData[questionNo -1].passageImage
                      }
                      key={testData[questionNo -1].qid}
                      style={{ maxWidth: "100%" }}
                      alt=""
                    />
                    : supportedFileExtensions.video.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                      <video
                        controls
                        src={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                          testData[questionNo -1].passageImageURL
                          :
                          v1_question_images_link + testData[questionNo -1].passageImage
                        }
                        key={testData[questionNo -1].qid}
                        style={{ maxWidth: "100%" }}
                        alt=""
                      />
                      : supportedFileExtensions.file.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                        <a style={{ color: "#337ab7", fontSize: "16px" }} href={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                          testData[questionNo -1].passageImageURL
                          :
                          v1_question_images_link + testData[questionNo -1].passageImage
                        }
                          download>CLICK TO DOWNLOAD</a>
                        :
                        null
                }
                <br/><br />
              </div>
            ) : (
              <></>
            )}< br />
          </Grid>
          :
          <></>}
        <Grid className='col-md-6 col-sm-12' style= {{overflow:"auto"}}>
          {/* <strong> Question No {questionNo}</strong>< br/>  */}
          <Typography
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <span className="question-num">Q.{questionNo} of {testData.length}</span>

          </Typography>
          {/* <Button> Question No {questionNo}</Button>< br/>  */}
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            PaperProps={{
              style: { height: '4%', textAlign: "center" },
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <h5>{testData[questionNo - 1].qid}</h5>
          </Popover>
          {(examDetail[0].selectedLanguage == null || examDetail[0].selectedLanguage == "en-IN" || examDetail[0].include_english == true) || (examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && testData[questionNo - 1].translated_qtext == null) ? <span style={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].qtext }} /> : null}
          {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_qtext == null) ? <><br /><br /></> : null}
          {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" ? <span dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].translated_qtext || translationMissingMessage }} style={{ fontSize: testData[questionNo - 1].translated_qtext ? "16px" : "10px" }} /> : null}
          {
            ((testData[questionNo -1].qresourcepath.split('|^^|')[0] !== undefined && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== null && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== "") ||
              (testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "")) ? (
              <div>
                <br />
                {supportedFileExtensions.image.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                  <img
                    src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                      testData[questionNo -1].question_resource_url.split('|^^|')[0]
                      :
                      v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                    }
                    key={testData[questionNo -1].qid}
                    style={{ maxWidth: "100%" }}
                    alt=""
                  />
                  : supportedFileExtensions.audio.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                    <audio
                      controls
                      src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                        testData[questionNo -1].question_resource_url.split('|^^|')[0]
                        :
                        v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                      }
                      key={testData[questionNo -1].qid}
                      style={{ maxWidth: "100%" }}
                      alt=""
                    />
                    : supportedFileExtensions.video.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                      <video
                        controls
                        src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                          testData[questionNo -1].question_resource_url.split('|^^|')[0]
                          :
                          v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                        }
                        key={testData[questionNo -1].qid}
                        style={{ maxWidth: "100%" }}
                        alt=""
                      />
                      : supportedFileExtensions.file.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                        <a style={{ color: "#337ab7", fontSize: "16px" }} href={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                          testData[questionNo -1].question_resource_url.split('|^^|')[0]
                          :
                          v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                        }
                          download>CLICK TO DOWNLOAD</a>
                        :
                        null
                }
              </div>
            ) : (
              <></>
            )
          }
        </Grid>
        <div className='vl' style={{border:"1px solid #d2d2d2"}}></div>
        <Grid className='mfib-qt-right col-md-5 col-sm-12' style={{overflowY:'scroll',height:'auto'}}>
            <div className="question-right">
              <br /><br />
              {
                Object.keys(tempJson).map((gridItem, index) => (
                  // brd-rgt
                  <div key={index} className="col-md-12"  >
                    <FormControl component="fieldset">

                      <FormLabel component="legend">{Object.keys(tempJson).sort()[index] === 'BLANK1' ? 'Option(i)' :
                        Object.keys(tempJson).sort()[index] === 'BLANK2' ? 'Option(ii)' : 'Option(iii)'}</FormLabel>
                      {Object.keys(tempJson)[index] === 'BLANK1' ?
                        <RadioGroup aria-label='Option(i)'
                          name='Option(i)'
                          value={value || ""}
                          onChange={(e) => handleChange(e)}
                          // defaultValue={value != '' ? value : ''}
                        >
                          {tempJson[Object.keys(tempJson)[0]].map((elem, elemIndex) => (
                            <FormControlLabel
                              key={elemIndex}
                              style={{ color: (value) === elem.split('|')[0] ? '#fff' : '#525252' }}
                              value={elem.split('|')[0]} 
                              control={<StyledRadio />}
                              label={<>
                                      {!((elem.split('|')[2] !== undefined && elem.split('|')[2] !== "undefined" && elem.split('|')[2] !== null && elem.split('|')[2] !== "") ||
                                        (elem.split('|')[3] !== undefined && elem.split('|')[3] !== "undefined" && elem.split('|')[3] !== null && elem.split('|')[3] !== "")) ? (
                                          <span>
                                            {(examDetail[0].selectedLanguage == null || examDetail[0].selectedLanguage == "en-IN" || examDetail[0].include_english == true) || (examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && elem.split('|')[4] == "--null--") ? <span dangerouslySetInnerHTML={{ __html: elem.split('|')[1] }} style={{ color: (value) === elem.split('|')[0] ? "#fff" : "#525252" }} /> : null}
                                            {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && (examDetail[0].include_english == true && elem.split('|')[4] == "--null--") ? <><br /><br /></> : null}
                                            {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" ? <span dangerouslySetInnerHTML={{ __html: elem.split('|')[4] != "--null--" ? elem.split('|')[4] : translationMissingMessage }} style={{ color: (value) === elem.split('|')[0] ? "#fff" : "#525252", fontSize: elem.split('|')[4] != "--null--" ? "" : "10px" }} /> : null}
                                          </span>
                                        ) : (
                                        <div>
                                          <br />
                                          {supportedFileExtensions.image.includes(elem.split('|')[2].split('.')[elem.split('|')[2].split('.').length - 1].toLowerCase()) ?
                                            <img
                                              src={(elem.split('|')[3] !== undefined && elem.split('|')[3] !== null && elem.split('|')[3] !== "") ?
                                                elem.split('|')[3]
                                                :
                                                v1_question_images_link + elem.split('|')[2]
                                              }
                                              key={elem.split('|')[0]}
                                              style={{ maxWidth: "100%" }}
                                              alt=""
                                            />
                                            : supportedFileExtensions.audio.includes(elem.split('|')[2].split('.')[elem.split('|')[2].split('.').length - 1].toLowerCase()) ?
                                              <audio
                                                controls
                                                src={(elem.split('|')[3] !== undefined && elem.split('|')[3] !== null && elem.split('|')[3] !== "") ?
                                                  elem.split('|')[3]
                                                  :
                                                  v1_question_images_link + elem.split('|')[2]
                                                }
                                                key={elem.split('|')[0]}
                                                style={{ maxWidth: "100%" }}
                                                alt=""
                                              />
                                              : supportedFileExtensions.video.includes(elem.split('|')[2].split('.')[elem.split('|')[2].split('.').length - 1].toLowerCase()) ?
                                                <video
                                                  controls
                                                  src={(elem.split('|')[3] !== undefined && elem.split('|')[3] !== null && elem.split('|')[3] !== "") ?
                                                    elem.split('|')[3]
                                                    :
                                                    v1_question_images_link + elem.split('|')[2]
                                                  }
                                                  key={elem.split('|')[0]}
                                                  style={{ maxWidth: "100%" }}
                                                  alt=""
                                                />
                                                : supportedFileExtensions.file.includes(elem.split('|')[2].split('.')[elem.split('|')[2].split('.').length - 1].toLowerCase()) ?
                                                  <a style={{ color: "#337ab7", fontSize: "16px" }} href={(elem.split('|')[3] !== undefined && elem.split('|')[3] !== null && elem.split('|')[3] !== "") ?
                                                    elem.split('|')[3]
                                                    :
                                                    v1_question_images_link + elem.split('|')[2]
                                                  }
                                                    download>CLICK TO DOWNLOAD</a>
                                                  :
                                                  null
                                          }
                                        </div>
                                      )}
                              </>}
                              className={(value) === elem.split('|')[0] ? "radio checked" : "radio"}
                              //label={elem.split('|')[1]} 
                              onClick={(e)=>getAnswer(e)}
                              // Object.keys(tempJson)[index] ===  'BLANK2' ? value1  == elem.split('|')[0] ? true : false: value2  == elem.split('|')[0] ? true : false}
                              checked={(value) === elem.split('|')[0] ? true : false}
                            />                           
                          ))
                          }


                        </RadioGroup>
                        : Object.keys(tempJson)[index] === 'BLANK2' ?
                          <RadioGroup aria-label='Option(ii)'
                            name='Option(ii)'
                            value={value1 ||""}
                            // defaultValue={value1 != '' ? value1 : ''}
                            onChange={(e) => handleChange1(e)}>
                            {tempJson[Object.keys(tempJson)[1]].map((elem, elemIndex) => (
                              <>
                              <FormControlLabel value={elem.split('|')[0]} control={<StyledRadio />}
                                onClick={(e)=>getAnswer(e)}
                                key={elemIndex}
                                style={{ color: (value1) === elem.split('|')[0] ? '#fff' : '#525252' }}

                                label={<>
                                        {!((elem.split('|')[2] !== undefined && elem.split('|')[2] !== "undefined" && elem.split('|')[2] !== null && elem.split('|')[2] !== "") ||
                                          (elem.split('|')[3] !== undefined && elem.split('|')[3] !== "undefined" && elem.split('|')[3] !== null && elem.split('|')[3] !== "")) ? (
                                            <span>
                                              {(examDetail[0].selectedLanguage == null || examDetail[0].selectedLanguage == "en-IN" || examDetail[0].include_english == true) || (examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && elem.split('|')[4] == "--null--") ? <span dangerouslySetInnerHTML={{ __html: elem.split('|')[1] }} style={{ color: (value1) === elem.split('|')[0] ? "#fff" : "#525252" }} /> : null}
                                              {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && (examDetail[0].include_english == true && elem.split('|')[4] == "--null--") ? <><br /><br /></> : null}
                                              {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" ? <span dangerouslySetInnerHTML={{ __html: elem.split('|')[4] != "--null--" ? elem.split('|')[4] : translationMissingMessage }} style={{ color: (value1) === elem.split('|')[0] ? "#fff" : "#525252", fontSize: elem.split('|')[4] != "--null--" ? "" : "10px" }} /> : null}
                                            </span>
                                          ) : (
                                          <div>
                                            <br />
                                            {supportedFileExtensions.image.includes(elem.split('|')[2].split('.')[elem.split('|')[2].split('.').length - 1].toLowerCase()) ?
                                              <img
                                                src={(elem.split('|')[3] !== undefined && elem.split('|')[3] !== null && elem.split('|')[3] !== "") ?
                                                  elem.split('|')[3]
                                                  :
                                                  v1_question_images_link + elem.split('|')[2]
                                                }
                                                key={elem.split('|')[0]}
                                                style={{ maxWidth: "100%" }}
                                                alt=""
                                              />
                                              : supportedFileExtensions.audio.includes(elem.split('|')[2].split('.')[elem.split('|')[2].split('.').length - 1].toLowerCase()) ?
                                                <audio
                                                  controls
                                                  src={(elem.split('|')[3] !== undefined && elem.split('|')[3] !== null && elem.split('|')[3] !== "") ?
                                                    elem.split('|')[3]
                                                    :
                                                    v1_question_images_link + elem.split('|')[2]
                                                  }
                                                  key={elem.split('|')[0]}
                                                  style={{ maxWidth: "100%" }}
                                                  alt=""
                                                />
                                                : supportedFileExtensions.video.includes(elem.split('|')[2].split('.')[elem.split('|')[2].split('.').length - 1].toLowerCase()) ?
                                                  <video
                                                    controls
                                                    src={(elem.split('|')[3] !== undefined && elem.split('|')[3] !== null && elem.split('|')[3] !== "") ?
                                                      elem.split('|')[3]
                                                      :
                                                      v1_question_images_link + elem.split('|')[2]
                                                    }
                                                    key={elem.split('|')[0]}
                                                    style={{ maxWidth: "100%" }}
                                                    alt=""
                                                  />
                                                  : supportedFileExtensions.file.includes(elem.split('|')[2].split('.')[elem.split('|')[2].split('.').length - 1].toLowerCase()) ?
                                                    <a style={{ color: "#337ab7", fontSize: "16px" }} href={(elem.split('|')[3] !== undefined && elem.split('|')[3] !== null && elem.split('|')[3] !== "") ?
                                                      elem.split('|')[3]
                                                      :
                                                      v1_question_images_link + elem.split('|')[2]
                                                    }
                                                      download>CLICK TO DOWNLOAD</a>
                                                    :
                                                    null
                                            }
                                          </div>
                                        )}
                                    </>}

                                className={(value1) === elem.split('|')[0] ? "radio checked" : "radio"}
                                checked={
                                  (value1) === elem.split('|')[0] ? true : false
                                }
                              />
                                </>
                            ))
                            
                            }

                          </RadioGroup>

                          :
                          <RadioGroup aria-label='Option(iii)'
                            name='Option(iii)'
                            value={value2 || ""}
                            //    defaultValue = {value2}
                            onChange={(e) => handleChange2(e)}>

                            {tempJson[Object.keys(tempJson)[2]].map((elem, elemIndex) => (
                              <FormControlLabel value={elem.split('|')[0] || ""} control={<StyledRadio />}
                                onClick={(e)=>getAnswer(e)}
                                key={elemIndex}
                                style={{ color: (value2) === elem.split('|')[0] ? '#fff' : '#525252' }}
                                label={<>
                                        {!((elem.split('|')[2] !== undefined && elem.split('|')[2] !== "undefined" && elem.split('|')[2] !== null && elem.split('|')[2] !== "") ||
                                          (elem.split('|')[3] !== undefined && elem.split('|')[3] !== "undefined" && elem.split('|')[3] !== null && elem.split('|')[3] !== "")) ? (
                                            <span>
                                              {(examDetail[0].selectedLanguage == null || examDetail[0].selectedLanguage == "en-IN" || examDetail[0].include_english == true) || (examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && elem.split('|')[4] == "--null--") ? <span dangerouslySetInnerHTML={{ __html: elem.split('|')[1] }} style={{ color: (value2) === elem.split('|')[0] ? "#fff" : "#525252" }} /> : null}
                                              {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && (examDetail[0].include_english == true && elem.split('|')[4] == "--null--") ? <><br /><br /></> : null}
                                              {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" ? <span dangerouslySetInnerHTML={{ __html: elem.split('|')[4] != "--null--" ? elem.split('|')[4] : translationMissingMessage }} style={{ color: (value2) === elem.split('|')[0] ? "#fff" : "#525252", fontSize: elem.split('|')[4] != "--null--" ? "" : "10px" }} /> : null}
                                            </span>
                                          ) : (
                                          <div>
                                            <br />
                                            {supportedFileExtensions.image.includes(elem.split('|')[2].split('.')[elem.split('|')[2].split('.').length - 1].toLowerCase()) ?
                                              <img
                                                src={(elem.split('|')[3] !== undefined && elem.split('|')[3] !== null && elem.split('|')[3] !== "") ?
                                                  elem.split('|')[3]
                                                  :
                                                  v1_question_images_link + elem.split('|')[2]
                                                }
                                                key={elem.split('|')[0]}
                                                style={{ maxWidth: "100%" }}
                                                alt=""
                                              />
                                              : supportedFileExtensions.audio.includes(elem.split('|')[2].split('.')[elem.split('|')[2].split('.').length - 1].toLowerCase()) ?
                                                <audio
                                                  controls
                                                  src={(elem.split('|')[3] !== undefined && elem.split('|')[3] !== null && elem.split('|')[3] !== "") ?
                                                    elem.split('|')[3]
                                                    :
                                                    v1_question_images_link + elem.split('|')[2]
                                                  }
                                                  key={elem.split('|')[0]}
                                                  style={{ maxWidth: "100%" }}
                                                  alt=""
                                                />
                                                : supportedFileExtensions.video.includes(elem.split('|')[2].split('.')[elem.split('|')[2].split('.').length - 1].toLowerCase()) ?
                                                  <video
                                                    controls
                                                    src={(elem.split('|')[3] !== undefined && elem.split('|')[3] !== null && elem.split('|')[3] !== "") ?
                                                      elem.split('|')[3]
                                                      :
                                                      v1_question_images_link + elem.split('|')[2]
                                                    }
                                                    key={elem.split('|')[0]}
                                                    style={{ maxWidth: "100%" }}
                                                    alt=""
                                                  />
                                                  : supportedFileExtensions.file.includes(elem.split('|')[2].split('.')[elem.split('|')[2].split('.').length - 1].toLowerCase()) ?
                                                    <a style={{ color: "#337ab7", fontSize: "16px" }} href={(elem.split('|')[3] !== undefined && elem.split('|')[3] !== null && elem.split('|')[3] !== "") ?
                                                      elem.split('|')[3]
                                                      :
                                                      v1_question_images_link + elem.split('|')[2]
                                                    }
                                                      download>CLICK TO DOWNLOAD</a>
                                                    :
                                                    null
                                            }
                                          </div>
                                        )}
                                </>}
                                className={(value2) === elem.split('|')[0] ? "radio checked" : "radio"}
                                //  checked={ testData[questionNo -1].selectedChoiceId.indexOf(elem.split('|')[0] ) > -1 ? true : false}

                                // checked =  { Object.keys(tempJson)[index] ===  'BLANK1' ? value  == elem.split('|')[0] ? true : false: 
                                // Object.keys(tempJson)[index] ===  'BLANK2' ? value1  == elem.split('|')[0] ? true : false: value2  == elem.split('|')[0] ? true : false}
                                checked={
                                  (value2) === elem.split('|')[0] ? true : false
                                }
                              />
                            ))

                            }

                          </RadioGroup>
                      }
                    </FormControl>
                  </div>
                ))
              }
            </div>
          </Grid>
      </Grid>
    </>
  );
}