import axiosInstance from "../utils/axiosConfig";

const API_USER_HOME = async (typeButton) => {
  const response = await axiosInstance.post("/dashboard/userHome", {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
    selectedTab: typeButton.newValue,
    organizationId: typeButton.org,
  });
  return response.data;
};

const dashboardService = {
  API_USER_HOME,
};

export default dashboardService;
