import React from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";

// import Paper from '@material-ui/core/Paper';
// import TextField from '@material-ui/core/TextField';
// import clsx from "clsx";
import cache from 'memory-cache';
import Typography from '@material-ui/core/Typography';
import {Popover} from '@material-ui/core';
import { WEB_URL } from '../../../utils/constants';
import onWindowReload from '../../../utils/reloadFunction';
const useStyles = makeStyles(theme => ({
    root: {
      display: "flex"
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding:'0px 5px',
    },
    resField : {
      float:'right',top:'-110px'
    },
    resField2 : {
      float: window.innerWidth < 1000 ? null : 'right',top: window.innerWidth < 1000 ? '-50px' : '-110px',
      padding: window.innerWidth < 1000 ? "18.5px 0px" : 'right',top: window.innerWidth < 1000 ? '-50px' : '-110px'
    
    }  
}));

export default (props) => {
    const classes = useStyles();
    // const theme = useTheme();

    const {testData, questionNo, setClearFlag, clearFlag, examId, setCheckFlag, examDetail
            , answerStatus, setAnswerStatus, marksScored, setMarksScored, totalMarks, setTotalMarks,
            wrongAnswerCount, setWrongAnswerCount,disable,setDisable,setTestData,windowSwitchCount,setWindowSwitch} = props;
            const [tempJson, setTempJson] = React.useState({});


    const [answer, setAnswer] = React.useState('');//testData[questionNo -1].selectedChoiceId?testData[questionNo -1].selectedChoiceId:''
    const [error, setError] = React.useState(false);
    const [ans, setAns] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
  
    onWindowReload(examId,examDetail,setWindowSwitch,setTestData,windowSwitchCount,testData)

    const open = Boolean(anchorEl);


   
    const onAnswerText = (index, e) => {
        setClearFlag(true);
        setCheckFlag(true);
       

        let newStr = answer.split('|^^|'); // or newStr = [...str];
            newStr[index] =e.target.value;
            
       // let newVal = answer.split('|^^|')[index].replace('',e.target.value)
        setAnswer(newStr.join('|^^|'))


        let temp = answer;
        //temp[questionNo -1] = (e.target.value === testData[questionNo -1].choicetext) ? testData[questionNo -1].qmarks : 0;
        setAnswerStatus(temp);
        testData[questionNo -1].answerStatus = (testData[questionNo -1].selectedChoiceId !== '') ? true : false;
        
        
        if(e.target.value !== ''){
            testData[questionNo -1].buttonStyle = 'Answered';
            setError(false);
        }else{
            testData[questionNo -1].buttonStyle = 'Not Answered';
            if(!examDetail[0].answermandatoryflag){
                setError(true);
            }
        }
        testData[questionNo -1].selectedChoiceId = newStr.join('|^^|');
        let collectData = {quesData: testData, examDetail: examDetail}
        let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
        window.localStorage.setItem(examId, encode);
        
    }

    React.useEffect(() => {
      
      if(questionNo === testData.length ){
        

        setDisable(true);
      }
        setAnswer(testData[questionNo -1].selectedChoiceId?testData[questionNo -1].selectedChoiceId:'');
        
       // setAns("");
       // setAnswer('');
       // setTempJson({});
        let tempOption = [];
        let tempJson1 = {};
         testData[questionNo - 1].chkey.split('|^^|').map((item, index) => {
            if(tempOption.indexOf(item) === -1){
              tempOption.push(item);
              tempJson1[item] = [];
            }
            tempJson1[item].push(testData[questionNo - 1].choiceid.split('|^^|')[index]+'|'+
            testData[questionNo - 1].choicetext.split('|^^|')[index]);
         })
    
        let ans = '';
        if(Object.keys(tempJson1).length > 1){
        for(let i = 1; i <Object.keys(tempJson).length ; i++){
            ans+= ' |^^|';
            } 
            setAns(ans);
        }
    
      //  if(testData[questionNo -1].selectedChoiceId!==null ||testData[questionNo -1].selectedChoiceId!=="" ){
      //   setTempJson({});

      //  }else{
        setTempJson(tempJson1);

      //  }
       


        if(clearFlag){
            
            
          testData[questionNo -1].selectedChoiceId = "";
        testData[questionNo -1].descriptiveText = "";
        testData[questionNo -1].descriptive_text = "";
          let temp = answer;
          //temp[questionNo -1] = 0;
          setAnswerStatus(temp);
          
          setAnswer("");
          setAns("");
          setTempJson({});
          testData[questionNo -1].buttonStyle = 'Not Answered';
          let collectData = {quesData: testData, examDetail: examDetail}
          let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
          window.localStorage.setItem(examId, encode);
          
          setClearFlag(false);
        }
        
      },[questionNo,answer,answerStatus,clearFlag])

    return (
        <>
            {/* <strong> Question No {questionNo}</strong>< br/>  */}
            <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
       <span className="question-num">Q.{questionNo} of {testData.length}</span>
      </Typography>
        {/* <Button> Question No {questionNo}</Button>< br/>  */}
        <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        // PaperProps={{
        //   style: { height: '4%' ,textAlign:"center"},
        // }}
        open={open}
        anchorEl={anchorEl}
         anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <h5>{testData[questionNo -1].qid}</h5>
      </Popover>
                               
      
            <span style={{color:"#525252",fontSize:"16px"}} dangerouslySetInnerHTML={{__html: testData[questionNo -1].qtext}} />
            {
                        testData[questionNo -1].qresourcepath !== '' ?
                      <>  <br />
                      <br />
                        <img src={`${WEB_URL}ah-resources/questionimages/`+testData[questionNo -1].qresourcepath.split('|^^|')[0]}  />
                        </>
                        : <></>
                      }
            <br />
            <br />
            {testData[questionNo -1].choicetext.split('|^^|').map((item ,index) => 
                            (
                              <>
                <div className="row form-group" key={index} >
            <label for="inputText" className="col-lg-9 col-sm-8" control-label   gutterBottom>
                {item}
                </label> 
                <div className="col-lg-3 col-sm-4">     
                {
                (answer !== 'null') ?
                <input 
                type="text"
                placeholder="Enter your input here"
                className="form-control"
                value={answer.split('|^^|')[index]} 
                onChange={(e) => onAnswerText(index, e)}
                
                // className={classes.resField2}
                 /> :
                 <input 
                 placeholder="Enter your input here"
                 type="text"
                 onChange={(e) => onAnswerText(index, e)}
                
                // className={classes.resField2}
                className="form-control"
                value="" 
                 />
                 
                }
                </div>
                
                
              
        </div>
        <br/><br/> 
        </>
        
              ))}
             {/* <div className="row form-group">
					      	<label for="inputText" className="col-lg-9 col-sm-8 control-label">Respond in Kind</label>
					      	<div className="col-lg-3 col-sm-4">
					        	<input type="text" className="form-control" id="inputText" placeholder="Enter your input here">
					      	</div>
					    </div> */}
            
     {/* {error
            ?
            <TextField id="outlined-search" 
            error
            label="Enter your Answer" 
            type="search" 
            variant="outlined"
            onChange={onAnswerText}
           // defaultValue={answer}
            helperText="Kindly enter your answer" />
            :
            <TextField id="outlined-search" 
            label="Enter your Answer" 
            type="search" 
            variant="outlined"
            value={answer}
            onChange={onAnswerText} />
            } 
             */}
        </>
    )
}