import axios from "../utils/axiosConfig";

const modalHandler = async(row,index,name,props,setLoading,setModelShow,setMsgModal,setResumeLoader,setMessageModal,setMessage,setCodeLanguages,selectedLanguage) => {
    const {setExamId,setTestData,setModal} = props;
    setLoading({index:index,status:true,name:name}); 
    setResumeLoader({index:index,status:true,name:name});
    setExamId(row.userExamId);
    let decode;
    let timeRemaining;
    // if(window.localStorage.getItem(row.userExamId) !== null){
    // decode = decodeURIComponent(atob(decodeURIComponent(window.localStorage.getItem(row.userExamId))))}
    // else {
    window.localStorage.clear()
    await axios.post('/test/answeredQts',{
        headers: {
            'Access-Control-Allow-Origin': '*',
          },   id: row.userExamId
      }).then((examData) => {      
        if(examData.data.result.length > 0) {
         window.localStorage.setItem(row.userExamId,examData.data.result)
         let decodedData = decodeURIComponent(atob(decodeURIComponent(examData.data.result)))         
          let decodedString = JSON.stringify(decodedData)
         let parsedDecode = JSON.parse(decodedString)
          decode = parsedDecode;
          timeRemaining = JSON.parse(decode)[0] && JSON.parse(decode)[0].examDetail[0] && JSON.parse(decode)[0].examDetail[0].time_remaining;
          // setTestData({quesData: JSON.parse(decode)[0].quesData,examDetail:JSON.parse(decodedData)[0].examDetail});
        }
      })
    // }
    if(decode){
      decode = JSON.stringify(decode);
      decode = JSON.parse(decode);
      var parsedExamData = JSON.parse(decode);      
      setTestData({quesData : parsedExamData.quesData,examDetail : parsedExamData.examDetail});
      setModal(true);
    }
  
    // if(decode === null || decode === '[object Object]' || decode === undefined){
    //if(!window.localStorage.getItem(row.userExamId)){
      
    let bodyObj = decode !== undefined ? {
      headers: {
          'Access-Control-Allow-Origin': '*',
        },  examId: row.userExamId, remainingTime: parsedExamData[0] ? parsedExamData[0].examDetail[0].time_remaining : parsedExamData.examDetail[0].time_remaining, selectedLanguage
    } : {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },  examId: row.userExamId, selectedLanguage
    }
    let statusMessage
    if(decode === null || decode === '[object Object]' || decode === undefined){
    axios.post('/test/userTest', bodyObj).then(testData => {
     
      // console.log('testData===>', testData);
      if (testData.data) {
        statusMessage = testData.data.msg == "SUCCESS" ? undefined : testData.data.msg
      }
      if(testData.data.quesData !== undefined) {
      let decodeQuesData  = decodeURIComponent(atob(decodeURIComponent(testData.data.quesData)))
      if(decode === null || decode === '[object Object]' || decode === undefined){        
      setTestData({quesData: JSON.parse(decodeQuesData), examDetail: testData.data.examDetail});
      }else{
      setTestData({quesData: JSON.parse(decode).quesData, examDetail: JSON.parse(decode).examDetail})
      }}
      else if(testData.data.quesData === undefined){
        setMsgModal(true);
        setModelShow(true);
        setMessageModal(testData.data.msg);
      }
      let collectData = {quesData: testData.data.quesData, examDetail: testData.data.examDetail}
      let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
      if(testData.data.examDetail[0].status === '000'){
        window.localStorage.setItem(row.userExamId, encode)
      }
      setModal(true);
      setLoading({status:false,index:"",name:''})
      setResumeLoader({index:'',status:false,name:''})
    }).catch(error => {
      console.log('error===>', error);
      setMessageModal('System Warning')
      if (statusMessage) {
        setMessage(statusMessage)
      }
      else {
        setMessage('Connectivity with the server has been lost. Please retry after some time.');
      }
      setMsgModal(true);
      setModelShow(true);
    })
    }
    // }else{
    //   setTestData(JSON.parse(decode));
    //   //setTestData(window.localStorage.getItem(row.userExamId));
    //   setModal(true);
    // }   
    await axios.get('/test/programlanguages',{
      headers: {
          'Access-Control-Allow-Origin': '*',
        }
    }).then((result) => { 
      setCodeLanguages(result.data)
    })
  }

export default modalHandler