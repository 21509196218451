import React from 'react';
import AnswerUserInfo from './Test/AnswerUserInfo';
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from '@material-ui/core/Button';


const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius : "10px"
    
  }
}));
export default (props) => {
  const classes = useStyles();
    const {testData, setQuestionNo, questionNo, setQuestionChange, navFlag} = props;

    const questionClicked = (questionNumber) => {
   
      setQuestionNo(questionNumber);
      setQuestionChange(true);
      //setQuestion()
    }
    const [anchorEl, setAnchorEl] = React.useState("false");

    const handleClick = () => {
      setAnchorEl("true");
      document.getElementById("main-layout").style.background = '#dddddd'
      document.getElementById("main-layout").style.display= 'block'
      document.getElementById("main-body").style.overflow='hidden'      
    };

    const handleClose = () => {
      setAnchorEl("false");
      document.getElementById("main-layout").style.display= ''
      document.getElementById("main-layout").style.background = ''
      document.getElementById("main-body").style.overflow=''
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
        {
        anchorEl === "false" ?
        <li className="nav-line-right" onClick={handleClick} style={{cursor:'pointer'}}>
        <img  onClick={handleClick} style={{cursor:'pointer'}} src={require('../images/queno.png')}/>
        </li>
        :<li className="nav-line-right" onClick={handleClose}  style={{cursor:'pointer'}}>
        <img   onClick={handleClick} style={{cursor:'pointer'}} src={require('../images/queno.png')}/>
        </li>
        }

        {/* {
          anchorEl === "true" ?
          <>
        <div className="queno-drop-two" 
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
						<ul className="list-inline questionno-list">
            {testData.map((value, index) => (
                <li onClick={props.navFlag === true?()=>questionClicked(index+1):''} >
                  <a 
                  className={(value.buttonStyle === 'Answered') ? 'visited' : (value.buttonStyle === 'Not Answered') ? 'left' : (value.buttonStyle === 'Marked Review') ? 'review' : (questionNo === (index+1)) ? 'active' :''}
                   
                >
                  {index + 1}</a></li>
            ))}
						</ul>

	   </div>
     
     </>
     :
     <></>
              } */}
              {
          anchorEl === "true" ?
               <Modal
      disableEnforceFocus = {true}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      PaperProps={{
        style: {
          backgroundColor:"#e5e5e9"
        }
      }}
    >
      <Fade in={anchorEl}>
        {/* <div className={classes.paper}> */}
        
        <div className="queno-drop-two">
						<ul className="list-inline questionno-list">
            {testData.map((value, index) => (
                <li onClick={props.navFlag === true?()=>questionClicked(index+1):''} >
                  <a 
                  className={(value.buttonStyle === 'Answered') ? 'visited' : (value.buttonStyle === 'Not Answered') ? 'left' : (value.buttonStyle === 'Marked Review') ? 'review' : (questionNo === (index+1)) ? 'active' :''}
                   
                >
                  {index + 1}</a></li>
            ))}
						</ul>

	   </div>
                 
       
      </Fade>
    </Modal> : <></>}

      </>
    );
}
