import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius : "15px",
    width:"380px",
    
  }
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const {showTimeOutModelOneMinute,setTimeoutModelOneMinuteShow} = props;
  
  const handleClose = () => {
    setTimeoutModelOneMinuteShow(false)
  };

  return (
    <div>
      
      <Modal
        disableEnforceFocus = {true}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showTimeOutModelOneMinute}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 60
        }}
      >
        <Fade in={showTimeOutModelOneMinute}>
        <div className={classes.paper} style={{textAlign:"justify"}}>
          <div style={{textAlign:"center"}}>

<h3 id="transition-modal-title" style={{color:'#6f2981',marginBottom:"30px"}}>System Warning</h3>
</div>
            <p id="transition-modal-description">
            You have left one minute after that your test will been auto-submitted.
            <br/>
            <br/>
            Thanking you, 
            <br/>
            Support, AssessHub.
            </p>
            <div className="form-group" style={{textAlign:"center"}}>
					      	<ul className="list-inline btn-list">
                <li><a className="btn btn-blue" style={{borderRadius:"10px"}} onClick={() => handleClose()} >
                <strong>Close</strong>
            </a>
                </li>
              
                </ul>
                </div>
            
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
