import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Table from './Table';
import MultiOrgDropDown from '../components/MultiOrg';
import {LoadingSkeletonGroupWise, LoadingSkeletonTableWise} from '../components/LoadingSkeleton';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  btnPadding: {
    marginRight: theme.spacing(0.5) - 5,
    marginBottom: theme.spacing(0.5) - 5,
    fontSize: "0.775rem",
    minHeight: "40px"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 80,
    padding: theme.spacing(3),
    // // overflow:"scroll",
  }
}));

export default function FullWidthGrid(props) {
  const classes = useStyles();
  const { loading, buttonArray, dispatch, started, pending, buttonName, setButtonClicked, typeButton,selectedTab ,setSelectedTab,
    setSelectedType, selectTypeButton, setTestData,examId, setExamId ,handleHome, handleIconCLick, isFeedback, menuTitle, setMenuTitle, setCodeLanguages} = props;
  const [tableData, setTabledata] = React.useState({});
  const handleGroupClick = (item) => {
    setButtonClicked(item);

    if (buttonArray[selectTypeButton] !== undefined) {
      setTabledata(buttonArray[selectTypeButton][item][0]);

    }
  }
  React.useEffect(() => {
    if(typeButton === 'Survey'){
      setMenuTitle(['Survey','SurveyCompleted','SurveyExpired'])
    }else if(typeButton === 'WorkFlow'){
      setMenuTitle(['WorkFlow','WorkFlowCompleted','WorkFlowExpired'])
    }else{
      setMenuTitle(['Tests','Completed','Expired'])
    }
    setSelectedType((typeButton === 'Tests' ? 'TEST' : (typeButton === 'Practice' ? 'PRACTISE' : (typeButton === 'Survey' ? 'SURVEY' : (typeButton === 'Feedback' ? 'FEEDBACK' : (typeButton === 'Completed' ? 'COMPLETED' : (typeButton === 'Expired' ? 'EXPIRED' : typeButton === 'WorkFlow' ? 'WORKFLOW' : '')))))));
    if (buttonArray[selectTypeButton] !== undefined && Object.keys(buttonArray[selectTypeButton]).length > 0 ) {
      setButtonClicked(Object.keys(buttonArray[selectTypeButton])[0]);
      setTabledata(buttonArray[selectTypeButton][Object.keys(buttonArray[selectTypeButton])[0]][0]);
    }else{
      setTabledata({}) 
    }
    // Need to check else condition.
  }, [buttonArray[selectTypeButton]])

  return (

    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className='row'>
        <MultiOrgDropDown selectedTab = {selectedTab} setSelectedTab = {setSelectedTab} handleHome={handleHome} typeButton={typeButton} handleIconCLick={handleIconCLick} isFeedback={isFeedback} menuTitle={menuTitle}/>
      </div>
      <br></br>
      {loading ? <LoadingSkeletonGroupWise /> : 
      <Grid item xs={12}>
        {buttonArray[selectTypeButton] !== undefined ?
          Object.keys(buttonArray[selectTypeButton]).map((item, index) => {
            if (item !== 'tableHeaders') {
              return (<Button key={`gButton${index}`} className={classes.btnPadding}
                variant="outlined" color={(buttonName === item) ? "primary" : "default"} style={{background: (buttonName === item) ? 'rgba(111, 41, 129, 0.13)' : ''}}
                onClick={() => handleGroupClick(item)}>
                {item}
              </Button>)
            }
          }) :
          <></>
        }

      </Grid>}

      <Grid item xs={12}>

        {/* <Paper className={classes.paper}>xs=12 sm=6</Paper> */}
        {loading ? <LoadingSkeletonTableWise /> : 
        <><Table dispatch={dispatch} tableData={tableData} typeButton={typeButton} status='000'
          setModal={props.setModal} setTestData={setTestData} examId = {examId} setExamId={setExamId} buttonName={buttonName} setCodeLanguages={setCodeLanguages} /> 
        <p className="button-text" style={{ color: '#B45E9B', marginBottom: "0px" }}>Kindly click on the 'Link' under Action column to proceed:</p></>
        }

        {/* {tableHeader !== '' ? 
      <Table tableData={tableData} tableheader={tableHeader} status='000' />
      :
      <></>
      } */}

      </Grid>
      {/* <Grid item xs={12} >
    <h2>Not Started</h2>
      
      <Table tableData={tableData} typeButton={typeButton} status='001' />
    </Grid> */}


    </main>
  );
}