import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
// import typography from './typography';
import overrides from './overrides';
import '../index.css';

const theme = createMuiTheme({
  palette,
  typography: {
    fontFamily: [
      '"Open Sans"',
      'sans-serif' 
    ].join(','),},
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

export default theme;
