import React from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from "@material-ui/core/IconButton";
import Brightness from '@material-ui/icons/Brightness7';
import Dark from '@material-ui/icons/Brightness4';
import Cookies from 'js-cookie';
// import axios from 'axios';

const useStyles = makeStyles(theme => ({
    root: {
      display: "flex"
    },
    paper: {
        padding: theme.spacing(2)
    },
    title: {
      flexGrow: 1,
    }
}));

export default (props) => {
    const classes = useStyles();
    // const theme = useTheme();
    const {examDetail, closeThankyou, evaluation} = props;
    // let token = Cookies.get('token');

    
    return (
        <>
            <Grid container spacing={1} style={{backgroundColor: (props.themeType === 'dark') ? '#303030' : '#FFF', alignContent: 'center'}}>
                <Grid item xs={12}>
                    <AppBar position="static" color="secondary">
                        <Toolbar>
                            <Typography variant="h6" className={classes.title}>
                                {examDetail[0].instancename}
                            </Typography>
                            <IconButton onClick={() => props.changeTheme(props.themeType)}  aria-label="show 4 new mails" color="inherit">
                                {props.themeType === 'dark' ? <Dark /> : <Brightness />}
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Grid>
                <Grid item xs={12} className={classes.paper}>

                    <div dangerouslySetInnerHTML={{__html: examDetail[0].thankyoumessage}} style={{paddingLeft:'5rem'}}  />
                    
                </Grid>
                {examDetail[0].showscore 
                ?
                <Grid item xs={12}  style={{textAlign: 'center', marginTop: '5rem', paddingLeft:'5rem'}}>
                    <h5>Obtained Marks: {evaluation.obtainedMarks}</h5>
                    <h5>Total Marks: {evaluation.totalMarks}</h5>
                    <h5>Percentage: {evaluation.percentage}%</h5>
                </Grid>
                :
                <></>
                }
                
                <Grid item xs={12}  style={{textAlign: 'center', marginTop: '5rem', paddingLeft:'5rem'}}>
                    
                    <Button variant="contained" color="primary" onClick={() => closeThankyou()}>
                        <strong>Close</strong>
                    </Button>
                    
                </Grid>
            
            </Grid>
        </>
    );
}