import React from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";

// import Paper from '@material-ui/core/Paper';
// import TextField from '@material-ui/core/TextField';
// import clsx from "clsx";
// import cache from 'memory-cache';
import {Popover,Typography} from '@material-ui/core';
import onWindowReload from '../../../utils/reloadFunction';
const useStyles = makeStyles(theme => ({
    root: {
      display: "flex"
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding:'0px 5px',
    },
}));

export default (props) => {

    const classes = useStyles();
    // const theme = useTheme();

    const {testData, questionNo, setClearFlag, clearFlag, examId, setCheckFlag, examDetail
            , answerStatus, setAnswerStatus, marksScored, setMarksScored, totalMarks, setTotalMarks,
            wrongAnswerCount, setWrongAnswerCount,disable,setDisable,setTestData,windowSwitchCount,setWindowSwitch} = props;

    const [answer, setAnswer] = React.useState(testData[questionNo -1].selectedChoiceId);
    const [error, setError] = React.useState(false);
    const [rows, setRows] = React.useState([{}]);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
  
    onWindowReload(examId,examDetail,setWindowSwitch,setTestData,windowSwitchCount,testData)

    const open = Boolean(anchorEl);

    const handleChange = idx => e => {

        const { name, value } = e.target;
        const rowVal = [...rows];
        rowVal[idx] = {
          [name]: value
        };
        setRows(rowVal);
        setAnswer(JSON.stringify(rows));
        testData[questionNo -1].selectedChoiceId = JSON.stringify(rows);
        testData[questionNo -1].buttonStyle = 'Answered';
      };

      const handleAddRow = () => {

        const item = {
          desc1: "",
          desc2: ""
        };
        setRows([...rows, item]);
        setAnswer(JSON.stringify(rows));
        testData[questionNo -1].selectedChoiceId = JSON.stringify(rows);
        testData[questionNo -1].buttonStyle = 'Answered';
        
      };

      const handleRemoveRow = () => {

        setRows(rows.slice(0, -1));
        setAnswer(JSON.stringify(rows));
        testData[questionNo -1].selectedChoiceId = JSON.stringify(rows);
        testData[questionNo -1].buttonStyle = 'Answered';
        
      };

      const handleRemoveSpecificRow = (idx) => () => {

        const rowVal = [...rows]
        rowVal.splice(idx, 1)
        setRows(rowVal);
        setAnswer(JSON.stringify(rows));
        testData[questionNo -1].selectedChoiceId = JSON.stringify(rows);
        testData[questionNo -1].buttonStyle = 'Answered';

      }
React.useEffect(()=>{
  
  if(questionNo === testData.length ){
    

    setDisable(true);
  }
})
      return (
        <>
            {/* <strong> Question No {questionNo}</strong>< br/>  */}
            <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
       <span className="question-num">Q.{questionNo} of {testData.length}</span>
      </Typography>
        {/* <Button> Question No {questionNo}</Button>< br/>  */}
        <Popover
        id="mouse-over-popover"
        className={classes.popover}
         classes={{
          paper: classes.paper,
        }}
       // PaperProps={{
        //   style: { height: '4%' ,textAlign:"center"},
        // }}
        open={open}
        anchorEl={anchorEl}
         anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
         <h5>{testData[questionNo -1].qid}</h5>
      </Popover>
            <span style={{color:"#525252"}} dangerouslySetInnerHTML={{__html: testData[questionNo -1].qtext}} />
            <br />
            <br />
            <div>
                <div className="container">
                <div className="row clearfix">
                    <div className="col-md-12 column">
                    <table
                        className="table table-bordered table-hover"
                        id="tab_logic"
                    >
                        <thead>
                        <tr>
                            <th className="text-center"> # </th>
                            <th className="text-center"> Desc1 </th>
                            <th className="text-center"> Desc2 </th>
                            <th />
                        </tr>
                        </thead>
                        <tbody>
                        {rows.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                            <td>{idx}</td>
                            <td>
                                <input
                                type="text"
                                name="desc1"
                                value={rows[idx].desc1}
                                onChange={handleChange(idx)}
                                className="form-control"
                                />
                            </td>
                            <td>
                                <input
                                type="text"
                                name="desc2"
                                value={rows[idx].desc2}
                                onChange={handleChange(idx)}
                                className="form-control"
                                />
                            </td>
                            <td>
                                <button
                                className="btn btn-outline-danger btn-sm"
                                onClick={handleRemoveSpecificRow(idx)}
                                >
                                Remove
                                </button>
                            </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <button onClick={handleAddRow} className="btn btn-primary">
                        Add Row
                    </button>
                    <button
                        onClick={this.handleRemoveRow}
                        className="btn btn-danger float-right"
                    >
                        Delete Last Row
                    </button>
                    </div>
                </div>
                </div>
            </div>
        </>
        
      );
}