import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  AppBar,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import SpeechToText from 'speech-to-text';
import axios from 'axios';
import supportedLanguages from './supportedLanguages';
import Sentiment from 'sentiment';
import emoji from 'node-emoji';
import { WEB_URL } from '../../utils/constants';

const styles = theme => ({
  root: {
    paddingTop: 65,
    paddingLeft: 11,
    paddingRight: 11
  },
  flex: {
    flex: 1
  },
  grow: {
    flexGrow: 1
  },
  paper: theme.mixins.gutters({
    paddingTop: 22,
    paddingBottom: 22
  })
});

class SpeechToTextDemo extends Component {


  state = {
    error: '',
    interimText: '',
    finalisedText: [],
    listening: false,
    language: 'en-IN',//en-US
    emojiValue:0,
    // big5 = [0, 0, 0, 0, 0]
  };

  componentDidMount(){
    this.startListening();
  }
  onAnythingSaid = text => {
    this.setState({ interimText: text });
  };

  onEndEvent = () => {
    if (!isWidthUp('sm', this.props.width)) {
      this.startListening();
    } else if (this.state.listening) {
      this.startListening();
    }
  };

  onFinalised = text => {
    this.setState({
      finalisedText: [text, ...this.state.finalisedText],
      interimText: ''
    });
    const sentiment = new Sentiment();
    const result = sentiment.analyze(text);
    this.setState({emojiValue:result.score});
    this.props.setSentiment(result.score);
    this.getBigFive(text);
  };

  startListening = () => {
    try {
      this.listener = new SpeechToText(
        this.onFinalised,
        this.onEndEvent,
        this.onAnythingSaid,
        this.state.language
      );
      this.listener.startListening();
      this.setState({ listening: true });
    } catch (err) {
      
      
    }
  };

  stopListening = () => {
    this.listener.stopListening();
    this.setState({ listening: false });
  };

  getBigFive = (text) => {
    return new Promise(async(resolve, reject) => {
      try {
        if(text !== null && text !== ''){
          let predictions = await axios.get(`${WEB_URL}predict/${text}`,{
            headers: {
              'Access-Control-Allow-Origin': '*',
            }
          });
          
          

          let big5 = await [parseInt(parseFloat(predictions.data.pred_prob_cOPN).toFixed(2) * 100),
            parseInt(parseFloat(predictions.data.pred_prob_cCON).toFixed(2) *100),
            parseInt(parseFloat(predictions.data.pred_prob_cEXT).toFixed(2) * 100),
            parseInt(parseFloat(predictions.data.pred_prob_cAGR).toFixed(2) * 100),
            parseInt(parseFloat(predictions.data.pred_prob_cNEU).toFixed(2) *100)];
         // await this.setState({big5})
          await this.props.setBig5Value(big5)
          
           this.props.setBig5(parseInt(parseFloat(predictions.data.pred_prob_cOPN).toFixed(2) * 100),
           parseInt(parseFloat(predictions.data.pred_prob_cCON).toFixed(2) *100),
           parseInt(parseFloat(predictions.data.pred_prob_cEXT).toFixed(2) * 100),
           parseInt(parseFloat(predictions.data.pred_prob_cAGR).toFixed(2) * 100),
           parseInt(parseFloat(predictions.data.pred_prob_cNEU).toFixed(2) *100));
        }else{
          

          let big5 = await [25, 66, 45, 80, 20];
          await this.props.setBig5Value({big5})
         // await this.setState({big5});
        }
        resolve({});
        
      } catch (error) {
        
        reject(error);
      }
    })
  }

  render() {
    const {
      error,
      interimText,
      finalisedText,
      listening,
      language
    } = this.state;
    const { classes } = this.props;
    let content;
    if (error) {
      content = (
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom>
            {error}
          </Typography>
        </Paper>
      );
    } else {
      let buttonForListening;

      if (listening) {
        buttonForListening = (
          <Button color="primary" onClick={() => this.stopListening()}  variant="contained">
            Stop
          </Button>
        );
      } else {
        buttonForListening = (
          <Button
            id="autoClickBtn"
            color="primary"
            onClick={() => this.startListening()}
            variant="contained"
            
          >
            Start
          </Button>
        );
      }
      content = (
        <Grid container spacing={16}>
          <Grid item xs={12} md={7}>
            <Paper className={this.props.classes.paper}>
              <Grid container spacing={16}>
              <Grid item xs={12} lg={6}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Language</InputLabel>
                    <Select
                      value={language}
                      onChange={evt =>
                        this.setState({ language: evt.target.value })
                      }
                      disabled={listening}
                    >
                      {supportedLanguages.map(language => (
                        <MenuItem key={language[1]} value={language[1]}>
                          {language[0]}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* <FormHelperText>
                      What language are you going to speak in?
                    </FormHelperText> */}
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6} style={{paddingLeft: '40px',
              paddingTop: '10px'}}>
                  {/* <Typography variant="overline" gutterBottom>
                    Status: {listening ? 'listening...' : 'finished listening'}
                  </Typography> */}
                  {buttonForListening}
                </Grid>

              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={5}>
            <Paper className={this.props.classes.paper}>
            Status: <span style={{color:this.props.themeType === 'dark' ?'#00acc1':'#1ab7ea'}}>  {listening ? 'Listening...' : 'Finished'} </span> <br />
              <Typography variant="overline" gutterBottom>
                Current utterances
              </Typography>
              <Typography variant="body1" gutterBottom>
                {interimText}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
          <p style={{color:this.props.themeType === 'dark' ?'#00acc1':'#1ab7ea', marginLeft: '23px'}}> 
            
              <strong>Sentiment analysis Score : {
                            this.state.emojiValue >0 ? this.state.emojiValue <5 ?` ${emoji.get('slightly_smiling_face')}`:this.state.emojiValue <10?
                            `${emoji.get('yum')}` :this.state.emojiValue <20?
                            `${emoji.get('wink')}`:this.state.emojiValue <30?
                            `${emoji.get('smile')}`:
                            `${emoji.get('joy')}`
                            : 
                            this.state.emojiValue < -2 ?
                            `${emoji.get('confused')}` :this.state.emojiValue < -5?
                            `${emoji.get('pensive')}`:this.state.emojiValue < -8?
                            `${emoji.get('disappointed')}`:this.state.emojiValue < -11?
                            `${emoji.get('angry')}`:this.state.emojiValue < -14?
                            `${emoji.get('rage')}` : `${emoji.get('neutral_face')}`
                            } {this.state.emojiValue}</strong></p>
            <Paper className={classes.paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Translated Text</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{'height': '150px', 'overflow':'scroll', 'display': 'block'}}>
                  {finalisedText.map((str, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                        {str}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container>
        <AppBar position="static">
          <Toolbar variant="">
            <Typography variant="h6" className={classes.grow} color="inherit">
            What language are you going to speak in?
            </Typography>
          </Toolbar>
        </AppBar>
        {content}
       
      </Grid>
    );
  }
}

export default withWidth()(withStyles(styles)(SpeechToTextDemo));