import React, { useRef, useEffect } from 'react';

const useUnload = fn => {
  const cb = React.useRef(fn);

  React.useEffect(() => {
    const onUnload = cb.current;
    window.addEventListener('beforeunload', onUnload);
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [cb]);
};

// const useUnload = fn => {
//   const cb = useRef();
//   cb.current = fn;

//   useEffect(() => {
//     const onUnload = () => cb.current();
//     if (document.readyState === 'interactive') {
//      onUnload();
//     }
//     else {
//       window.addEventListener("beforeunload", onUnload);

//     }
//    return () => window.removeEventListener("beforeunload", onUnload);
//   }, [cb]);
// };

export default useUnload;