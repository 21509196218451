import * as React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { useSelector, useDispatch } from 'react-redux'
import { addUserHome, getLoading, setGroupDetails, getGroupDetails, setLoading, setOrgId, getOrgId, getTypeButton, setTypeButton,setUserHomeDetails ,getUserHomeDetails,setType , getType, setSelectedType, getSelectedType, setTestData, getTestData, setOrgData } from '../reducer/dashboardSlice';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import axios from '../utils/axiosConfig'
export default function MenuTab(props) {
  const {handleHome, handleIconCLick, menuTitle,selectedTab,setSelectedTab} = props;
  const typeButton = useSelector(getTypeButton);
  const [value, setValue] = React.useState(selectedTab ? selectedTab : typeButton);
  const orgId = useSelector(getOrgId);
  const dispatch = useDispatch();
  let token = Cookies.get('token');
  useEffect(() => {
    setValue(selectedTab ? selectedTab : typeButton)
  })

  const handleChange = (event,newValue) => {
    setSelectedTab(newValue);
    setValue(newValue);
    let value = 0;
    if(newValue == 'Tests' || newValue == 'Feedback' || newValue == 'Survey' || newValue == 'WorkFlow' ){
        value = 0;
    }else if(newValue == 'Completed' || newValue == 'FeedbackCompleted' || newValue == 'CompletedSurvey' || newValue == 'CompletedWorkFlow'){
        value = 1;
    }else{
        value = 2;
    }
    let data = {
        newValue: newValue,
        org : orgId,
        
    }
    
    dispatch(addUserHome(data)).then((response)=>{
        // dispatch(setLoading())
        dispatch(setUserHomeDetails(response.payload))
        dispatch(setTypeButton(newValue))
        // dispatch(setGroupDetails(response.payload.groups))
        dispatch(setOrgId(response.payload.latestOrg))
        dispatch(setOrgData(response.payload.org))
        let typeData = response.payload;
        handleHome(typeData,newValue)
      }).catch((e)=>{
          console.log(e)
      })
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        variant="scrollable"
        value={value}
        key={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="scrollable secondary tabs"
      >
        <Tab key={menuTitle[0]} value={menuTitle[0]} label={menuTitle[0] === 'Tests' ? "Pending Test" : menuTitle[0] === 'Survey' ? "Pending Survey" : menuTitle[0] === 'WorkFlow' ? 'Pending WorkFlow' : "Pending Feedback"}/>
        <Tab key={menuTitle[1]} value={menuTitle[1]} label={menuTitle[1] === 'Completed' ? "Completed Test" : menuTitle[1] === 'SurveyCompleted' ? "Completed Survey" : menuTitle[1] === 'WorkFlowCompleted' ? 'Completed WorkFlow' : "Completed Feedback"} />
        <Tab key={menuTitle[2]} value={menuTitle[2]} label={menuTitle[2] === 'Expired' ? "Expired Test" : menuTitle[2] === 'SurveyExpired' ? "Expired Survey" : menuTitle[2] === 'WorkFlowExpired' ? 'Expired WorkFlow' :"Expired Feedback"}/>
      </Tabs>
    </Box>
  );
}