import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Table from './ExpiredTable';
import MultiOrgDropDown from './MultiOrg';
import {LoadingSkeletonGroupWise, LoadingSkeletonTableWise} from '../components/LoadingSkeleton';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  btnPadding: {
    marginRight: theme.spacing(0.5) - 5,
    marginBottom: theme.spacing(0.5) - 5,
    fontSize: "0.775rem",
    minHeight: "40px"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 80,
    padding: theme.spacing(3),
    // overflow:"scroll",
  }
}));

export default function FullWidthGrid(props) {
  const classes = useStyles();
  const { handleIconCLick, loading, setLoading, dispatch, buttonArray, started, pending, buttonName, setButtonClicked, typeButton, setSelectedType, selectTypeButton,handleHome, menuTitle, setMenuTitle,selectedTab ,setSelectedTab} = props;
  const [tableData, setTabledata] = React.useState({});

  const handleGroupClick = (item) => {
    setButtonClicked(item);

    if (buttonArray[selectTypeButton] !== undefined) {
      setTabledata(buttonArray[selectTypeButton][item]);

    }
  }

  console.log('tabledata', tableData)

  React.useEffect(() => {
    if(typeButton === 'FeedbackExpired'){
      setMenuTitle(['Feedback','FeedbackCompleted','FeedbackExpired'])
      setSelectedType('FEEDBACK_EXPIRED');
    }else if(typeButton === 'SurveyExpired'){
      setMenuTitle(['Survey','SurveyCompleted','SurveyExpired'])
      setSelectedType('SURVEY_EXPIRED');
    }else if(typeButton === 'WorkFlowExpired'){
      setMenuTitle(['WorkFlow','WorkFlowCompleted','WorkFlowExpired'])
      setSelectedType('WORKFLOW_EXPIRED')
    }else{
      setMenuTitle(['Tests','Completed','Expired'])
      setSelectedType('EXPIRED');
    }
    if (buttonArray[selectTypeButton] !== undefined && buttonArray[selectTypeButton][Object.keys(buttonArray[selectTypeButton])[0]] !== undefined) {
      setButtonClicked(Object.keys(buttonArray[selectTypeButton])[0]);
      setTabledata(buttonArray[selectTypeButton][Object.keys(buttonArray[selectTypeButton])[0]]);

    }else{
      setTabledata({})
    }
    // else {
    //   setTabledata((data) => {
    //     data['tableHeaders'] = "S.No,Assessment Name,Status,Test Date/Time,Action"
    //     return data;
    //   })
    // }
  }, [buttonArray[selectTypeButton]])

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className='row'>
        <MultiOrgDropDown selectedTab = {selectedTab} setSelectedTab = {setSelectedTab} handleIconCLick={handleIconCLick} handleHome={handleHome} typeButton={typeButton} menuTitle={menuTitle}/>
      </div>
      {/* <p className="button-text" style={{color: '#B45E9B',marginBottom:"0px"}}>Kindly click on the 'Link' under Action column to proceed:</p>
  */}
      <br></br>
      {loading ? <LoadingSkeletonGroupWise />  :
      <Grid style={{ paddingBottom: "0px" }} item xs={12}>
        {buttonArray[selectTypeButton] !== undefined ?
          Object.keys(buttonArray[selectTypeButton]).map((item, index) => {
            if (item !== 'tableHeaders') {
              return (<Button key={`gButton${index}`} className={classes.btnPadding}
                variant="outlined" color={(buttonName === item) ? "primary" : "default"}
                onClick={() => handleGroupClick(item)}>
                {item}
              </Button>)
            }
          }) :
          <></>
        }


        {/* <Button  className={classes.btnPadding} variant="outlined" color="primary">
                      Primary
                </Button> */}

      </Grid>}

      <Grid item xs={12}>

        {/* <Paper className={classes.paper}>xs=12 sm=6</Paper> */}
        {loading ? <LoadingSkeletonTableWise /> :
        <Table tableData={tableData} typeButton={typeButton} status='000' />
        }


      </Grid>
      {/* <Grid item xs={12} >
          <h2>Not Started</h2>

            <Table tableData={tableData} typeButton={typeButton} status='001' />
          </Grid> */}

    </main>
  );
}