import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import {
    Typography,  
    AppBar,
    Toolbar
  } from '@material-ui/core';

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      //textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

export default function Big5Data(props){
 const classes = useStyles();
    return(
        <>
        <AppBar position="static">
          <Toolbar variant="">
            <Typography variant="h6" className={classes.grow} color="inherit">
            Big Five Analysis
            </Typography>
          </Toolbar>
        </AppBar>
        <div>
        {
              props.big5.map((item, index) => {
                if(index === 0){
                  let list = (item >= 50 ? 
                    <li data-selectable-paragraph="">Inventiveness and intellectual curiosity, having a preference for variety over routine, and seeking fulfillment in intense, euphoric experiences.</li>
                    :
                    <li data-selectable-paragraph="">Consistency and caution, seeking fulfillment in perseverance, tending to be pragmatic and data-driven.</li>
                    )
                    return (
                      <div>
                        <p data-selectable-paragraph="" style={{color: props.themeType === 'dark' ?'#00acc1':'#1ab7ea'}} ><strong>Openness to experience</strong></p>
                        <ul>{list}</ul>
                      </div>
                    )
                }
                if(index === 1){
                  let list = (item >= 50 ? 
                    <li data-selectable-paragraph="">Efficiency and organization. Tendency to be self-disciplined and dependable.</li>
                    :
                    <li data-selectable-paragraph="">Easy-going and perhaps careless. Flexible and spontaneous, but can be perceived as unreliable, sloppy.</li>
                    )
                    return (
                      <div>
                        <p data-selectable-paragraph="" style={{color:props.themeType === 'dark' ?'#26a69a':'#0084ff'}}><strong>Conscientiousness</strong></p>
                        <ul>{list}</ul>
                      </div>
                    )
                }
                if(index === 2){
                  let list = (item >= 50 ? 
                    <li data-selectable-paragraph="">A tendency towards being outgoing and energetic. Assertiveness, talkativeness, positive emotions, and a tendency to seek the company of others. High extraversion can be perceived as attention-seeking and domineering.</li>
                    :
                    <li data-selectable-paragraph="">A tendency towards being solitary and reserved. Often a reflective personality, though low extraversion can be perceived as aloof or self-absorbed.</li>
                    )
                    return (
                      <div>
                       <p data-selectable-paragraph="" style={{color:props.themeType === 'dark' ?'#80cbc4':'#39539E'}}><strong>Extraversion</strong></p>
                        <ul>{list}</ul>
                      </div>
                    )
                }
                if(index === 3){
                  let list = (item >= 50 ? 
                    <li data-selectable-paragraph="">A tendency to be friendly, compassionate, and cooperative. Indicates ones trusting and helpful nature, and whether one is well-tempered.</li>
                    :
                    <li data-selectable-paragraph="">An analytic and detached tendency. Often competitive or challenging; can be seen as argumentative or untrustworthy.</li>
                    )
                    return (
                      <div>
                       <p data-selectable-paragraph="" style={{color:props.themeType === 'dark' ?'#4dd0e1':'#0077B5'}}><strong>Agreeableness</strong></p>
                        <ul>{list}</ul>
                      </div>
                    )
                }
                if(index === 4){
                  let list = (item >= 50 ? 
                    <li data-selectable-paragraph="">A high emotional reactiveness and vulnerability to stress. Neuroticism may correlate with perceiving many situations as threatening.</li>
                    :
                    <li data-selectable-paragraph="">Tendency to be calm, emotionally stable, and free from persistent negative feelings. However, freedom from negative feelings does not mean that low-scorers experience a lot of positive feelings.</li>
                    )
                    return (
                      <div>
                       <p data-selectable-paragraph="" style={{color:props.themeType === 'dark' ?'#b2ebf2':'#0f83a8'}}><strong>Neuroticism</strong></p>
                        <ul>{list}</ul>
                      </div>
                    )
                }
              })
            }
          
          </div>
          </>
    );
}