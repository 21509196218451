import React from 'react';
import {withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Cookies from 'js-cookie';
import cache from 'memory-cache';
import axios from '../utils/axiosConfig';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

const StyledTableRow = withStyles(theme => ({
root: {
    '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.default,
    },
},
}))(TableRow);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  btnPadding :{
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 80,
    padding: theme.spacing(3),
    // overflow:"scroll",
  }
}));

export default function FullWidthGrid(props) {
  const classes = useStyles();
  const {buttonArray, started, pending, buttonName, setButtonClicked, typeButton, 
    setSelectedType, selectTypeButton, setTestData, setExamId} = props;
  setSelectedType('PRACTISE');
  
  let token = Cookies.get('token');
  const [tableData, setTabledata] = React.useState({});
  const [modal, setModal] = React.useState(false);
  const [message, setMessage] = React.useState('');
  
  const handleGroupClick = (item) => {
    setButtonClicked(item);
    
    
  }

  const handleClose = () => {
    setModal(false)
  }

  const modalHandler = (row) => {
    

    axios.post('/dashboard/practice', {
      // axios.post('http://192.168.31.35:3006/v3/dashboard/practice', {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },practice: row
      }).then(practiceData => {
        
        
        setModal(true);
        setMessage((practiceData.data.userExamId !== null || practiceData.data.userExamId !== undefined) ? 
            'Exam is already assigned hence cant assign' : 'Exam is assigned');
      }).catch(error => {
        
        
      })
  }
  React.useEffect(() => {
    
  
    if(buttonArray[selectTypeButton] !== undefined){
      
      setButtonClicked(Object.keys(buttonArray[selectTypeButton])[0]);
      setTabledata(buttonArray[selectTypeButton][Object.keys(buttonArray[selectTypeButton])[0]][0]);
      
    }
  }, [buttonArray[selectTypeButton]])

  return (
    <main className={classes.content}>
        
        <div className={classes.toolbar} />
        <h2>{typeButton}</h2>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button key={`gButton`} className={classes.btnPadding} 
                variant="outlined" color={"primary"} >
                    Practice
            </Button>
               
                
                {/* <Button  className={classes.btnPadding} variant="outlined" color="primary">
                      Primary
                </Button> */}
            
          </Grid>
          
          <Grid item xs={12}>
            <p style={{color: '#B45E9B'}}>Kindly click on the 'Link' under Action column to proceed:</p>
          
            <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            {
                            
                            ['S.No', 'Test Name', 'Questions', 'Duration (Minutes)', 'Test'].map((item,index) => {
                                return (<StyledTableCell key={index}>{item}</StyledTableCell>)
                            })
                            
                            }
                        
                            {/* <StyledTableCell align="right">Protein&nbsp;(g)</StyledTableCell> */}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {buttonArray['PRACTISE'].map((item, index) => (
                                <StyledTableRow key={`pract_${index}`}>
                                    <StyledTableCell component="th" scope="row">
                                    {index+1}
                                    </StyledTableCell>
                                    <StyledTableCell >{item.test_name}</StyledTableCell>
                                    <StyledTableCell >{item.numberofquestions}</StyledTableCell>
                                    <StyledTableCell >{item.duration} </StyledTableCell>
                                    <StyledTableCell >
                                        <Button variant="outlined" color="primary" onClick={() => modalHandler(item)}>
                                            Apply
                                        </Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
                    <Snackbar open={modal} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={message !== 'Exam is assigned' ? 'error' : "success"}>
                            {message}
                        </Alert>
                    </Snackbar>
          </Grid>
          
        </Grid>
    </main>
  );
}