import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
// import FaceIcon from '@material-ui/icons/Face';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import IconButton from "@material-ui/core/IconButton";
// import Badge from '@material-ui/core/Badge';
// import MailIcon from '@material-ui/icons/Mail';
// import NotificationsIcon from '@material-ui/icons/Notifications';
// import MoreIcon from '@material-ui/icons/MoreVert';
// import Brightness from '@material-ui/icons/Brightness7';
// import Dark from '@material-ui/icons/Brightness4';
// import AccountCircle from '@material-ui/icons/AccountCircle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ProfileList from './List';

const useStyles = makeStyles(theme => ({
  root: {
    right:'0px',
    width: '100%',
    maxWidth: '200px',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(0),
    padding:'2px'
  },
}));

export default function NestedList(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      
      className={classes.root}
    >
      <ListItem style={{padding:'2px'}} button onClick={handleClick}>
      <ListItem button className={classes.nested}>
            <ListItemIcon>
              <ProfileList />
            </ListItemIcon>
          </ListItem>
      </ListItem>
    </List>
  );
}
