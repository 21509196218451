import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MicOffIcon from "@material-ui/icons/MicOff";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
// import IconButton from "@material-ui/core/IconButton";
// import Brightness from '@material-ui/icons/Brightness7';
// import Dark from '@material-ui/icons/Brightness4';
import Cookies from "js-cookie";
import axios from "../../utils/axiosConfig";
import IPCapture from "./ImageProctoringCapture";
import { LoaderContained } from "../LoaderProcessing";
import right_snap from "../../images/right-snap.png";
import wrong_snap from "../../images/wrong-snap.png";
import speech_warning from "../../images/speech-instructions.png";
// import video_warning from "../../images/video_warning.png"
// import speech_warning_mobile from "../../images/speech-instructions-mobile.png";
import video_warning_mobile from "../../images/video_mobile_warning.jpg";
// import speech_warning_desktop from "../../images/speech-instructions.png";
import video_warning_desktop from "../../images/video_warning.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Instruction(props) {
  const classes = useStyles();
  // const theme = useTheme();
  const { examDetail, setViewInstruction, testData, setTestData, MonitoringData, isAutoSpeechTest, isVideoInterviewTest } = props;
  let token = Cookies.get("token");

  const [hidden, setHidden] = useState(false);
  // const [showMicModal, setShowMicModal] = useState(false);
  const [videoWarningModal, setVideoWarningModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [monitorFlag, setMontorFlag] = useState(examDetail[0].monitoringflag);
  const [deviceType, setDeviceType] = useState("desktop"); // Assume desktop as default

  const closeInstruction = () => {
    window.location.replace("/v2/user/dashboard");
  };

  window.onbeforeunload = function (e) {
    e.preventDefault();
  };
  // Function to detect device type
  const detectDeviceType = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      setDeviceType("mobile");
    } else {
      setDeviceType("desktop");
    }
    console.log("Device Type:", deviceType);
  };

  // Call the function to detect device type when component mounts
  useEffect(() => {
    detectDeviceType();
  }, []);
  React.useEffect(() => {
    if (isVideoInterviewTest === true || isAutoSpeechTest === true || examDetail[0].speaker_dialect === true || examDetail[0].monitoringflag === true) {
      navigator.mediaDevices
        .getUserMedia({ audio: isVideoInterviewTest === true || isAutoSpeechTest === true || examDetail[0].speaker_dialect === true, video: isVideoInterviewTest === true || examDetail[0].monitoringflag === true })
        .then((stream) => {
          const recorder = new MediaRecorder(stream);
        })
        .catch((error) => {
          console.error("Error accessing microphone:", error);
          // set state to show the modal
          setVideoWarningModal(true);
        });
    }
  });
  // React.useEffect(() => {
  //   if (isAutoSpeechTest === true) {
  //     navigator.mediaDevices
  //       .getUserMedia({ audio: true })
  //       .then((stream) => {
  //         const recorder = new MediaRecorder(stream);
  //       })
  //       .catch((error) => {
  //         console.error("Error accessing microphone:", error);
  //         // Set state to show the modal
  //         setShowMicModal(true);
  //       });
  //   }
  // });

  React.useEffect(() => {
    if (examDetail[0].status == "001") {
      setMontorFlag(false);
      setHidden(false);
    }
  });

  const resumeTest = async () => {
    setLoading(true);
    if (examDetail[0].status === "000") {
      setTestData({ quesData: testData, examDetail: examDetail });
      // window.localStorage.setItem(examDetail[0].id,testData)
      let stringifyTestData = JSON.stringify(testData);
      let encodedTestData = encodeURIComponent(btoa(encodeURIComponent(stringifyTestData)));
      let stringifyExamDetail = JSON.stringify(examDetail);
      let encodedExamDetail = encodeURIComponent(btoa(encodeURIComponent(stringifyExamDetail)));
      axios
        .post("/test/startExam", {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          id: examDetail[0].id,
          selectedLanguage: examDetail[0].selectedLanguage,
          quesData: encodedTestData,
          examDetail: encodedExamDetail,
        })
        .then((updateStatus) => {
          examDetail[0].status = "001";
          setViewInstruction(false);
          setLoading(false);
        })
        .catch((error) => {});
    } else if (examDetail[0].status === "001") {
      let resumeData;
      resumeData = window.localStorage.getItem(examDetail[0].id);
      let decodedData = decodeURIComponent(atob(decodeURIComponent(resumeData)));
      let resumeTestData = JSON.parse(decodedData);
      setTestData({
        quesData: resumeTestData[0] ? resumeTestData[0].quesData : resumeTestData.quesData,
        examDetail: resumeTestData[0] ? resumeTestData[0].examDetail : resumeTestData.examDetail,
      });

      if (resumeTestData[0]) {
        let stringifyExamDetail = JSON.stringify(resumeTestData[0].examDetail[0]);
        var encodedExamDetail = encodeURIComponent(btoa(encodeURIComponent(stringifyExamDetail)));

        let stringifyQuesData = JSON.stringify(resumeTestData[0].quesData);
        var encodedQuesData = encodeURIComponent(btoa(encodeURIComponent(stringifyQuesData)));
      } else {
        let stringifyExamDetailNew = JSON.stringify(resumeTestData.examDetail[0]);
        var encodedExamDetailNew = encodeURIComponent(btoa(encodeURIComponent(stringifyExamDetailNew)));

        let stringifyQuesDataNew = JSON.stringify(resumeTestData.quesData);
        var encodedQuesDataNew = encodeURIComponent(btoa(encodeURIComponent(stringifyQuesDataNew)));
      }

      axios
        .post("/test/resumeExam", {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          id: examDetail[0].id,
          examDetail: resumeTestData[0] ? encodedExamDetail : encodedExamDetailNew,
          quesData: resumeTestData[0] ? encodedQuesData : encodedQuesDataNew,
        })
        .then((updateStatus) => {
          examDetail[0].status = "001";
          setViewInstruction(false);
          setLoading(false);
        })
        .catch((error) => {});
    } else {
      setViewInstruction(false);
    }
  };

  return (
    <>
      {/* <Grid container spacing={1} style={{backgroundColor: (props.themeType === 'dark') ? '#303030' : '#FFF', alignContent: 'justify'}}>
                <Grid item xs={12}> */}
      <div id="main-body" style={{ background: "#E5E6E9", maxHeight: "-webkit-fill-available" }}>
        <div className="container-fluid main-wrap">
          <AppBar position="static" className="col-md-12" color="secondary">
            <Toolbar style={{ minHeight: "32px" }}>
              <Typography variant="h6" className={classes.title}>
                {examDetail[0].instancename}
              </Typography>
              {/* <IconButton  onClick={() => props.changeTheme(props.themeType)} aria-label="show 4 new mails" color="inherit">
                                {props.themeType === 'dark' ? <Dark  /> : <Brightness  />}
                            </IconButton> */}
            </Toolbar>
          </AppBar>
        </div>
        <div className="row">
          <div className="container container-two qa-wrap">
            <div className="qa-overlay" id="main-layout"></div>
            <div className="col-md-12 question-box instruction-li" id="layout-2">
              <div id="inst" dangerouslySetInnerHTML={{ __html: examDetail[0].instruction_text }} />
            </div>
          </div>
        </div>
        {/* <div style={{textAlign: 'justify'}}>
                        <p>Important</p>
                        <p>Your test will be TERMINATED & considered as complete if you attempt to switch to another window.</p>
                        <p>Use of computer calculator will also be considered as switching window.</p>
                        <p>Please note : Your Test Score will be considered as INVALID.</p>
                    </div> */}
        <div className="instruction-div container qa-wrap ">
          <div className="qa-overlay" id="main-layout"></div>
          <div className="col-md-12 question-box" style={{ paddingTop: "0px", paddingBottom: "0px" }} id="layout-2">
            <h3 style={{ textAlign: "center", color: "#8D0001", marginTop: "10px", marginBottom: "10px" }}>
              <b>Important Information</b>
            </h3>
            <p style={{ marginBottom: "0px" }}>
              Your test will be{" "}
              <u>
                <b style={{ color: "#8D0001" }}>TERMINATED</b>
              </u>{" "}
              &amp; considered as complete if you attempt to switch to another window.
            </p>
            <p style={{ marginBottom: "0px" }}>Use of computer calculator will also be considered as switching window.</p>
            <p style={{ marginBottom: "0px" }}>
              Please note : Your Test Score will be considered as{" "}
              <u>
                <b style={{ color: "#8D0001" }}>INVALID</b>.
              </u>
            </p>
            {!hidden && monitorFlag ? (
              <></>
            ) : hidden ? (
              <></>
            ) : (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                {loading ? (
                  <LoaderContained />
                ) : (
                  <Button variant="contained" color="primary" onClick={() => resumeTest()}>
                    <strong>{examDetail[0].status === "000" ? "Start" : "Resume"}</strong>
                  </Button>
                )}

                {/* <Button variant="contained" style={{marginLeft: '2rem'}} color="primary" onClick={() => closeInstruction()}>
                            <strong>Close</strong>
                        </Button> */}
              </Grid>
            )}
          </div>
        </div>
        {monitorFlag != false ? (
          <div className="instruction-div-two container  qa-wrap ">
            <div className="qa-overlay" id="main-layout"></div>
            <div className="col-md-12  question-box" style={{ paddingTop: "0px", paddingBottom: "0px" }} id="layout-2">
              <h3 style={{ textAlign: "center", color: "#8D0001", marginTop: "10px", marginBottom: "10px" }}>
                <b>Web Cam Configurations</b>
              </h3>
              <p style={{ marginBottom: "0px" }}>
                This test needs to be conducted under monitored session only. Webcam is{" "}
                <u>
                  <b style={{ color: "#8D0001" }}>MANDATORY</b>
                </u>
                . This test will not start if webcam is not found.
              </p>
              <p style={{ marginBottom: "0px" }}>
                Basic System Requirements : Windows OS 7 and above, iOS High Sierra and above, Chrome 55 and above, Firefox 50 and above
              </p>
              <p style={{ marginBottom: "0px" }}>
                {" "}
                Click on the Start button. A small window might appear on the top of the page, asking you to allow access to your camera. Allow it.
                {/* inside a <span style={{ color: "#6ac159", fontWeight: "bold" }}>"green circle"</span> for AI to detect a face as shown in the image below. */}
              </p>
              <p style={{ marginBottom: "0px" }}>
                To snap photo. Please come closer to the camera so that your face is visible.
                {/* inside a <span style={{ color: "#6ac159", fontWeight: "bold" }}>"green circle"</span> for AI to detect a face as shown in the image below. */}
              </p>

              {/* <div style={{ textAlign: "center", margin: "20px 0", width: "100%" }} >
                                <img src={wrong_snap} alt="wrong-snap" style={{ width: "200px", margin: "0 20px" }} />
                                <img src={right_snap} alt="right-snap" style={{ width: "200px", margin: "0 20px" }} />
                            </div> */}

              <Grid item xs={12}>
                <IPCapture resumeTest={resumeTest} examDetail={examDetail} hidden={hidden} setHidden={setHidden} MonitoringData={MonitoringData} />
              </Grid>
            </div>
          </div>
        ) : (
          <> </>
        )}
      </div>
      {/* <Dialog open={showMicModal} onClose={() => setShowMicModal(false)}>
        <DialogTitle style={{ color: "#FF0000" }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
            <MicOffIcon style={{ fontSize: "2em", marginRight: "8px", color: "#000" }} />
            <b>You need to allow the mic permissions.</b>
          </div>
        </DialogTitle>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <img src={speech_warning} alt="Flow Diagram" style={{ maxWidth: "100%", maxHeight: "400px" }} />
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={() => setShowMicModal(false)} color="primary" variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog> */}
      {/* video warning modal */}
      {videoWarningModal && (
        <Dialog open={videoWarningModal} onClose={() => setVideoWarningModal(false)}>
          <DialogTitle style={{ color: "#FF0000" }}>
            <div style={{ display: "flex", alignItems: "center", textAlign: "left" }}>
              <MicOffIcon style={{ fontSize: "2em", marginRight: "8px", color: "#000" }} />
              <b style={{ marginLeft: "8px" }}>You need to allow{isVideoInterviewTest === true || examDetail[0].monitoringflag === true ? " camera " : ""}{(isVideoInterviewTest === true || examDetail[0].monitoringflag === true) && (isVideoInterviewTest === true || isAutoSpeechTest === true || examDetail[0].speaker_dialect === true) ? "and" : ""}{isVideoInterviewTest === true || isAutoSpeechTest === true || examDetail[0].speaker_dialect === true  ? " mic " : ""}permissions to proceed further.</b>
            </div>
          </DialogTitle>
          <DialogContent>
            <div style={{ textAlign: "center" }}>
              <img
                src={deviceType === "mobile" ? video_warning_mobile : video_warning_desktop}
                alt="Video Warning"
                style={{ maxWidth: "100%", maxHeight: "400px" }}
              />
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={() => setVideoWarningModal(false)} color="primary" variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* <Dialog open={videoWarningModal} onClose={() => setVideoWarningModal(false)}>
        <DialogTitle style={{ color: "#FF0000" }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
            <MicOffIcon style={{ fontSize: "2em", marginRight: "8px", color: "#000" }} />
            <b>You need to allow camera and mic permissions.</b>
          </div>
        </DialogTitle>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <img
              src={video_warning}
              alt="Flow Diagram"
              style={{ maxWidth: "100%", maxHeight: "400px" }}
            />
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={() => setShowMicModal(false)} color="primary" variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}
