import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Modal from './Test/WindowSwitch/SwitchModal';
import { Grid } from '@material-ui/core';
import axios from './../utils/axiosConfig';
import {LoaderProcessingOutlined} from './LoaderProcessing';
import modalHandler from '../utils/modalHandler';
import LanguageModal from './Test/LanguageModal';
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const useStyles = makeStyles({
  table: {
    minWidth: 340,
    // overflow:"scroll",
  },
  root: {
    display: 'flex',
    overflow: 'scroll',
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5
  }
  
});

export default function CustomizedTables(props) {
  const classes = useStyles();
  const {tableData, status, typeButton, setModal, setTestData,examId, setExamId,buttonName, showModal, setCodeLanguages} = props; 
  const [resumeLoader,setResumeLoader] = React.useState({index:"",status:false,name:""});
  const [ind, setInd] = React.useState(0);
  const [loading,setLoading] = React.useState({index:"",status:false,name:""});
  const [msgModal, setMsgModal] = React.useState(false);
  const [messageModal,setMessageModal] = React.useState('');
  const [message,setMessage] = React.useState('');
  const [showModel, setModelShow] = React.useState(false);
  const [showLanguageModal,setShowLanguageModal] = React.useState(false);
  const [name,setName] = React.useState();
  if(tableData['tableHeaders'] !== undefined){
  }
  
  return (
    <>
      <div className="listing_div" >
        <div className="list_head" >
  <p>
  {
              tableData && tableData['tableHeaders'] !== undefined ? 
              tableData['tableHeaders'].split(',').map((item,index) => {
          
                return ( <span  style={{lineHeight:'2'}} className={index === 0? "srno": index === 1?"exam_name":index === 2?"assessee_name":index === 3?"status":index === 4?"time":index === 5?"list_action-t":""} key={index}>{item}</span>)
              })
              :
              <></>
            }
  </p>
</div>
     {
            tableData['001'] !== undefined ? 
              tableData['001'].map((row, index) => {
                return(
                  <div key={index} className={index % 3 != 0 ? "list_even list_name2" : "list_odd list_name2"  }  >
                       <div key={index} className="list_detail">
                       <span className="srno">{index+1}</span> 
                     
                  
                       <span className="exam_name">{row.examName}</span>
                       <span className="assessee_name">{row.assessee ? row.assessee : '-'}</span>
                       <span className="status"><strong className="viewInMob">Status - </strong>IN PROGRESS</span> 
                       <span className="time">{new Date(row.date).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-')+"   "}{new Date(row.date).toLocaleTimeString([],{hour:'2-digit',minute:'2-digit',hour12:true})}</span>
                       </div>
                       <div className="list_action-t" >
                       
                       {resumeLoader.status && resumeLoader.index === index && resumeLoader.name === `resume_${buttonName}_${index}` ? <LoaderProcessingOutlined /> : 
                        <Button variant="outlined" name ={`resume_${buttonName}_${index}`} style={{ top: "-5px" }} color="primary" onClick={(e) => modalHandler(row, index,e,props,setLoading,setModelShow,setMsgModal,setResumeLoader,setMessageModal,setMessage,setCodeLanguages)}>
                          Resume
                        </Button>
                       } 
                    </div>
                  </div>
                );
              })
            :
            <></>
          }
          {
            tableData['000'] !== undefined ? 
              tableData['000'].map((row, index) => {
                return(
                  
                  <div key={index} className={(ind+index) % 2 != 0 ? "list_even list_name2" : "list_odd list_name2"   }>
                  <div className="list_detail">
                  <span className="srno">{ind+index+1}</span> 
                
             
                  <span className="exam_name">{row.examName}</span>
                  <span className="assessee_name">{row.assessee ? row.assessee : '-'}</span>
                  <span className="status"><strong className="viewInMob">Status - </strong>NOT STARTED</span> 
                  <span className="time">{new Date(row.date).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric',time:'numeric'}).replace(/ /g, '-')+"   "} {new Date(row.date).toLocaleTimeString([],{hour:'2-digit',minute:'2-digit',hour12:true})}</span>
                  </div>
                    <div className="list_action-t">
                      {
                         loading.status && loading.index === index && loading.name === `TakeTest_${buttonName}_${index}` ? 
                          <LoaderProcessingOutlined />
                          :
                           <Button name ={`TakeTest_${buttonName}_${index}`} disabled={new Date(row.date) > new Date()} variant="outlined" style={{ top: "-5px" }} color="primary" onClick={row.languageLocale != null && row.languageLocale.split(",").length > 1 ? (e) => { setName(e.currentTarget.name); setShowLanguageModal(true); } : (e) => { modalHandler(row, index,e.currentTarget.name,props,setLoading,setModelShow,setMsgModal,setResumeLoader,setMessageModal,setMessage,setCodeLanguages,row.languageLocale ? row.languageLocale.split("-")[0] : null) }}>
                             {`Take ${typeButton === 'Survey' ? 'Survey' : 'Test'} Online`}
                           </Button> 
                      } 
                    </div>

                    {showLanguageModal ?
                      <LanguageModal showLanguageModal={showLanguageModal} setShowLanguageModal={setShowLanguageModal} name={name} row={row} index={index} props={props} setLoading={setLoading} setModelShow={setModelShow} setMsgModal={setMsgModal} setResumeLoader={setResumeLoader} setMessageModal={setMessageModal} setMessage={setMessage} setCodeLanguages={setCodeLanguages} />
                    : <></> }
             </div>
                );
              })
            :
            <><div className="list_odd list_name" style={{padding:'0px',lineHeight:'3'}}  >
            <div className="list_detail" style={{textAlign:'center'}}>
              <span style={{fontSize:'15px',fontWeight:'bold'}}>{typeButton === 'Feedback' ? 'No Pending Feedback' : typeButton === 'Survey' ? 'No Pending Survey' : 'No Pending Tests'}</span>
            </div>
            </div></>
          }
        
      </div>
      {msgModal ?
          <Modal headerMessage={messageModal} message= {message ? message : ''} showModel={showModel} />
          : <>    </>}
    </>
  );
}
