import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
const useStyles = makeStyles({
    table: {
        minWidth: 340,
    },
    root: {
    },
    tableCell: {
        paddingRight: 4,
        paddingLeft: 5
    }
    
});
export default function CustomizedTables(props) {
    const classes = useStyles();
    const {tableData} = props;

    return (
        <Grid item xs={12}>
            <Paper className={classes.root}>
                <div className="listing_div">
                    <div className="list_head">
                        <p>
                            <span className="admin-srno" >Sr.No</span>
                            <span className="admin-name" >Username</span>
                            <span className="admin-browser" >Userexam Id</span>
                            <span className="admin-browser" >Group Name</span>
                            <span className="admin-device" >Assessment Name</span>
                        </p>
                    </div>
                    {
                        tableData && tableData.length > 0 ?
                            tableData.map((item,index) => {
                                return (
                                <><div key={index + Date.now()} className={index % 2 !== 0 ? "list_even list_name" : "list_odd list_name"}  >
                                <div key={index + Date.now()} className="list_detail_2">
                                <span className="admin-srno"><strong className="viewInMob">Srno : </strong>{index + 1}</span>
                                <span  className="admin-name">{item['username']}</span>
                                <span  className="admin-browser">{item['userexam_id']}</span>
                                <span  className="admin-browser">{item['group_name']}</span>
                                <span  className="admin-device">{item['assessment_name']}</span>
                                </div>
                                </div>
                                </>);
                                })
                                :
                                <><div className="list_odd list_name" style={{ padding: '0px', lineHeight: '3' }}  >
                                    <div className="list_detail" style={{ textAlign: 'center' }}>
                                        <span style={{ fontSize: '15px', fontWeight: 'bold' }}>No User Found</span>
                                    </div>
                                </div></>
                    }
                                  </div>
            </Paper>
        </Grid>
    );
}