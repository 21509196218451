import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "../components/ReGenerateTable";
import axios from "../../src/utils/axiosConfig";
import resultAxios from "../../src/utils/resultAxiosConfig";
import GenerateResultModal from "../components/Test/GenerateResultModal";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { LoadingSkeletonTableWise } from "../components/LoadingSkeleton";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  btnPadding: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: "0.775rem",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 80,
    padding: theme.spacing(3),
  },
}));
export default function FullWidthGrid(props) {
  const classes = useStyles();
  const { adminData, tableData, isGenerateTableLoading } = props;
  const [open, setOpen] = React.useState(false);
  const tabNames = ["Regenerate AnsweredQuestions", "Regenerate UserExam", "Regenerate Result"];
  const [tabSelected, setTabSelected] = useState(tabNames[0]);
  React.useEffect(() => {}, []);

  const updateV1 = () => {
    setOpen(true);
    axios
      .get("/test/updateV1", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        let res = response;
      })
      .catch((error) => {
        console.log("Error updateV1", error);
      });
  };

  const reGenerateResult = () => {
    setOpen(true);
    axios
      .get("/test/generate-result", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        let res = response;
      })
      .catch((error) => {
        console.log("Error reGenerateResult", error);
      });
  };

  const updateStatus = () => {
    setOpen(true);
    axios
      .get("/test/update-userexam", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        let res = response;
      })
      .catch((error) => {
        console.log("Error updateUserexam", error);
      });
  };

  const insertAnswers = () => {
    setOpen(true);
    axios
      .get("/test/getdataintopg", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        let res = response;
      })
      .catch((error) => {
        console.log("Error updateUserexam", error);
      });
  };

  const handleTabChange = (e, value) => {
    setTabSelected(value);
  };

  const addTestIntoQueue = async () => {
    resultAxios
      .get("/result/addintoqueue", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        console.log("response", response);
      })
      .catch((error) => {
        console.log("addintoqueue error", error);
      });
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <div className="row" style={{ display: "flex" }}>
          <Grid item xs={6}>
            <b>
              <h3 style={{ color: "#7030a0", fontSize: "20px", marginBottom: "20px" }}>Pending Results</h3>
            </b>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right", alignSelf: "center" }}>
            <Button
              className="generate-btn"
              variant="outlined"
              name="addIntoQueueBtn"
              id="addIntoQueueBtn"
              color="primary"
              onClick={() => addTestIntoQueue()}
            >
              Add into Queue
            </Button>
            {/* <Button className='generate-btn' variant="outlined" name='updateV1' id='updateV1' color="primary" disabled = {tableData.length <= 0} style={{ marginRight: "20px" }} onClick={() => updateV1()}>Update V1</Button> */}
            {/* {tabSelected == tabNames[0] ?
                        <Button className='generate-btn' variant="outlined" name='reGenerateResult' id='regenerateResult' color="primary" disabled = {tableData.length <= 0} onClick={() => insertAnswers()}>Insert Answers</Button>
                        : tabSelected == tabNames[1] ?
                        <Button className='generate-btn' variant="outlined" name='reGenerateResult' id='regenerateResult' color="primary" disabled = {tableData.length <= 0} onClick={() => updateStatus()}>Update Status</Button>
                        : tabSelected == tabNames[2] ?
                        <Button className='generate-btn' variant="outlined" name='reGenerateResult' id='regenerateResult' color="primary" disabled = {tableData.length <= 0} onClick={() => reGenerateResult()}>ReGenerate</Button>
                        :
                        null
                        } */}
          </Grid>
        </div>
        <GenerateResultModal open={open} setOpen={setOpen} />
        <Box sx={{ width: "100%" }}>
          <Tabs
            variant="scrollable"
            value={tabSelected}
            // key={value}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="scrollable secondary tabs"
          >
            <Tab key={tabNames[0]} value={tabNames[0]} label={tabNames[0]} />
            <Tab key={tabNames[1]} value={tabNames[1]} label={tabNames[1]} />
            <Tab key={tabNames[2]} value={tabNames[2]} label={tabNames[2]} />
          </Tabs>
        </Box>
        <br />
        <b>
          <h3 style={{ marginTop: "0px", color: "#7030a0", fontSize: "16px" }}>
            {tabSelected == tabNames[0] ? (
              <>(Note : Generate results for completed tests in MongoDB)</>
            ) : tabSelected == tabNames[1] ? (
              <>(Note : Change 'userexam' status and insert data in 'examresult')</>
            ) : tabSelected == tabNames[2] ? (
              <>(Note : Insert data in 'examresult')</>
            ) : null}
          </h3>
        </b>
        <br />
        <b>
          <h3 style={{ marginTop: "0px", color: "#7030a0", fontSize: "20px", marginBottom: "20px" }}>
            Total :{" "}
            {tableData
              ? tabSelected == tabNames[0]
                ? tableData.reGenerateListPendingAnswersInsertion && Array.isArray(tableData.reGenerateListPendingAnswersInsertion)
                  ? tableData.reGenerateListPendingAnswersInsertion.length
                  : 0
                : tabSelected == tabNames[1]
                ? tableData.reGenerateListPendingStatusUpdate && Array.isArray(tableData.reGenerateListPendingStatusUpdate)
                  ? tableData.reGenerateListPendingStatusUpdate.length
                  : 0
                : tabSelected == tabNames[2]
                ? tableData.reGenerateListReadyToRegenerate && Array.isArray(tableData.reGenerateListReadyToRegenerate)
                  ? tableData.reGenerateListReadyToRegenerate.length
                  : 0
                : 0
              : 0}
          </h3>
        </b>
        <Grid container spacing={3}>
          <Grid style={{ paddingBottom: "0px" }} item xs={12}></Grid>
          <Grid item xs={12}>
            {isGenerateTableLoading ? (
              <LoadingSkeletonTableWise />
            ) : tabSelected == tabNames[0] ? (
              <Table tableData={tableData.reGenerateListPendingAnswersInsertion} />
            ) : tabSelected == tabNames[1] ? (
              <Table tableData={tableData.reGenerateListPendingStatusUpdate} />
            ) : tabSelected == tabNames[2] ? (
              <Table tableData={tableData.reGenerateListReadyToRegenerate} />
            ) : null}
          </Grid>
        </Grid>
      </main>
    </>
  );
}
