import { serverConstants, localhostConstants } from "./serverConstants";

export const WEB_URL = "https://www.assesshub.co.in/";
export const SUPPORT_MAIL = "support@assesshub.com";

export const answersBaseURL =
  serverConstants.find(item => window.location.href.includes(item.urlUniqueString))
    ? serverConstants.find(item => window.location.href.includes(item.urlUniqueString)).answersBaseURL
    : localhostConstants.answersBaseURL;

export const resultBaseURL =
  serverConstants.find(item => window.location.href.includes(item.urlUniqueString))
    ? serverConstants.find(item => window.location.href.includes(item.urlUniqueString)).resultBaseURL
    : localhostConstants.resultBaseURL;

export const proctoringURL =
  serverConstants.find(item => window.location.href.includes(item.urlUniqueString))
    ? serverConstants.find(item => window.location.href.includes(item.urlUniqueString)).proctoringURL
    : localhostConstants.proctoringURL;