import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import axios from "../utils/axiosConfig";
import { LoaderProcessingOutlined } from "./LoaderProcessing";

const span = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 340,
    // overflow:"scroll",
  },
  root: {
    // display: 'flex',
    // overflow: 'scroll',
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});

export default function CustomizedTables(props) {
  const classes = useStyles();
  const { tableData, typeButton, buttonName, searchRecord, setManualGenerate, manualGenerate, setCompleted } = props;
  const [loading, setLoading] = React.useState({ index: "", status: false, name: "" });
  if (tableData !== undefined) {
  }

  const manualGenerateResult = (row, index, e) => {
    setCompleted(false);
    setManualGenerate(true);
    setLoading({ index: index, status: true, name: e.currentTarget.name });
    let examId = row.userExamId;
    axios
      .post("/test/manualGenerateResult", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        id: examId,
      })
      .then((response) => {
        setManualGenerate(false);
        setCompleted(true);
        setLoading({ index: "", status: false, name: "" });
      })
      .catch((error) => {
        console.log("Error manualGenerateResult", error);
      });
  };

  return (
    <Grid item xs={12}>
      <Paper className={classes.root}>
        <div className="listing_div">
          <div className="list_head">
            {searchRecord != true ? (
              <p>
                {tableData !== undefined ? (
                  tableData["tableHeaders"] !== undefined ? (
                    tableData["tableHeaders"].split(",").map((item, index) => {
                      return (
                        <span
                          style={{ lineHeight: "2", display: index === undefined ? "none" : "" }}
                          className={
                            index === 0
                              ? "srno"
                              : index === 1
                              ? typeButton !== "Completed" && typeButton !== "SurveyCompleted" && typeButton !== "WorkFlowCompleted"
                                ? "name-feedback"
                                : "name"
                              : index === 2 && item === "Test Status"
                              ? "status"
                              : index === 2 && item === "Role"
                              ? "status"
                              : index === 3 && item !== "Test Status"
                              ? "time"
                              : index === 3 && item === "Test Status"
                              ? "status"
                              : index === 4 && item === "Result Status"
                              ? "time"
                              : index === 4 && item !== "Result Status"
                              ? "status"
                              : index === 6
                              ? "time-c"
                              : ""
                          }
                          key={index}
                        >
                          {index === 6 ? "Test completed on" : item}
                        </span>
                      );
                    })
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </p>
            ) : (
              <p>
                {tableData !== undefined ? (
                  tableData["tableHeaders"] !== undefined ? (
                    tableData["tableHeaders"].split(",").map((item, index) => {
                      return (
                        <span
                          style={{ lineHeight: "2", display: index === undefined ? "none" : "" }}
                          className={
                            index === 0
                              ? "srno"
                              : index === 1
                              ? typeButton !== "Completed" && typeButton !== "SurveyCompleted" && typeButton !== "WorkFlowCompleted"
                                ? "name-feedback"
                                : "name namerecord"
                              : index === 2 && item === "Test Status"
                              ? "status"
                              : index === 2 && item === "Role"
                              ? "status"
                              : index === 3 && item !== "Test Status"
                              ? "time"
                              : index === 3 && item === "Test Status"
                              ? "status"
                              : index === 4 && item === "Result Status"
                              ? "time"
                              : index === 4 && item !== "Result Status"
                              ? "status"
                              : index === 6
                              ? "time-c"
                              : index == 5 && item == "Action"
                              ? "action"
                              : ""
                          }
                          key={index}
                        >
                          {index === 6 ? "Test completed on" : item}
                        </span>
                      );
                    })
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </p>
            )}
          </div>

          {tableData !== undefined ? (
            tableData["tableData"] !== undefined ? (
              buttonName !== "FEEDBACK_SURVEY" &&
              (typeButton === "Completed" || typeButton === "SurveyCompleted" || typeButton === "WorkFlowCompleted") ? (
                tableData.tableData.map((row, index) => {
                  return (
                    <>
                      <div className={index % 2 !== 0 ? "list_even list_name" : "list_odd list_name"}>
                        <div className="list_detail">
                          <span className="srno">{index + 1}</span>
                          {searchRecord != true ? <span className="name">{row.name}</span> : <span className="name namerecord">{row.name}</span>}
                          <span className="status">{row.testStatus}</span>
                          {searchRecord != true ? (
                            <>
                              <span className="time">
                                <strong className="viewInMob">Result Status - </strong>
                                {row.resultStatus}
                              </span>
                              <span className="list_action_2">
                                {row.resultDate == "N.A."
                                  ? "N.A."
                                  : new Date(row.resultDate)
                                      .toLocaleDateString("en-GB", { day: "numeric", month: "short", year: "numeric" })
                                      .replace(/ /g, "-") +
                                    "   " +
                                    new Date(row.resultDate).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="time timerecord">
                                <strong className="viewInMob">Result Status - </strong>
                                {row.resultStatus}
                              </span>
                              <span className="list_action_2 listaction_searchrecord">
                                {row.resultDate == "N.A."
                                  ? "N.A."
                                  : new Date(row.resultDate)
                                      .toLocaleDateString("en-GB", { day: "numeric", month: "short", year: "numeric" })
                                      .replace(/ /g, "-") +
                                    "   " +
                                    new Date(row.resultDate).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}
                              </span>
                            </>
                          )}
                          {searchRecord == true ? (
                            loading.status && loading.index === index && loading.name === `manual-regenerate_${buttonName}_${index}` ? (
                              <LoaderProcessingOutlined />
                            ) : (
                              <span>
                                <Button
                                  type="submit"
                                  variant="outlined"
                                  color="primary"
                                  disabled={manualGenerate == true ? true : false}
                                  name={`manual-regenerate_${buttonName}_${index}`}
                                  id="manual-regenerate"
                                  className={classes.submit}
                                  onClick={(e) => manualGenerateResult(row, index, e)}
                                >
                                  ReGenerate
                                </Button>
                              </span>
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  );
                })
              ) : <>
                  <div className="list_odd list_name" style={{ padding: "0px", lineHeight: "3" }}>
                    <div className="list_detail" style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                        {typeButton === "FeedbackCompleted"
                          ? "No Completed Feedback"
                          : typeButton === "SurveyCompleted"
                          ? "No Completed Survey"
                          : "No Completed Tests"}
                      </span>
                    </div>
                  </div>
                </> ? (
                buttonName === "FEEDBACK_SURVEY" &&
                typeButton !== "Completed" &&
                typeButton !== "SurveyCompleted" &&
                typeButton !== "WorkFlowComplete" &&
                tableData.tableData.map((row, index) => {
                  return (
                    <>
                      <div className={index % 2 !== 0 ? "list_even list_name" : "list_odd list_name"}>
                        <div className="list_detail">
                          <span className="srno">{index + 1}</span>
                          <span className="name-feedback">{row.assessee}</span>
                          <span className="status">{row.role}</span>
                          <span className="status">
                            <strong className="viewInMob">Status - </strong>
                            {row.testStatus}
                          </span>
                          <span className="time">
                            <strong className="viewInMob">Status - </strong>
                            {row.resultStatus}
                          </span>
                          <span className="time-c">
                            {new Date(row.testDate)
                              .toLocaleDateString("en-GB", { day: "numeric", month: "short", year: "numeric", time: "numeric" })
                              .replace(/ /g, "-") + "   "}{" "}
                            {new Date(row.testDate).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <></>
              )
            ) : (
              <>
                <div className="list_odd list_name" style={{ padding: "0px", lineHeight: "3" }}>
                  <div className="list_detail" style={{ textAlign: "center" }}>
                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                      {typeButton === "FeedbackCompleted"
                        ? "No Completed Feedback"
                        : typeButton === "SurveyCompleted"
                        ? "No Completed Survey"
                        : "No Completed Tests"}
                    </span>
                  </div>
                </div>
              </>
            )
          ) : (
            <></>
          )}
        </div>
      </Paper>
    </Grid>
  );
}
