export default [
  ['English (India)', 'en-IN'],
  ['हिन्दी (भारत)', 'hi-IN'],
  ['मराठी (भारत)', 'mr-IN'],
  ['ગુજરાતી (ભારત)', 'gu-IN'],
  ['বাংলা (ভারত)', 'bn-IN'],
  ['ಕನ್ನಡ (ಭಾರತ)', 'kn-IN'],
  ['മലയാളം (ഇന്ത്യ)', 'ml-IN'],
  ['தமிழ் (இந்தியா)', 'ta-IN'],
  ['తెలుగు (భారతదేశం)', 'te-IN'],
  ['اردو (بھارت)', 'ur-IN'],
  ];