import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import FaceIcon from '@material-ui/icons/Face';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {Link} from 'react-router-dom';

import Cookies from 'js-cookie';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function NestedList(props) {
  const classes = useStyles();
  let history = Cookies.get('reactHistory');
   //onClick={() => props.history.push('/user/profile/')}
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      
      className={classes.root}
    >
      <Link to='/v2/user/profile' style={{textDecoration: 'none', color: (props.themeType === 'dark' ? '#FFF' : '#000'), cursor : 'pointer',
        }}>
        <ListItem button  >
          <ListItemIcon>
           <AccountCircle/>
          </ListItemIcon>
          <h5>My Profile</h5>
          
        </ListItem>
      </Link>
      
      
      <Link to='/v2/user/logout' style={{textDecoration: 'none', color: (props.themeType === 'dark' ? '#FFF' : '#000'), cursor : 'pointer',
        }}>
      <ListItem button>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <h5>Logout</h5>
      </ListItem>
      </Link>
    </List>
  );
}
