import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useEffect } from "react";
// import CircularProgress from '@mui/material/CircularProgress';
// or
// import { CircularProgress } from '@mui/material';
// import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius : "15px",
    width:"390px",
  }
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const {showModel, setModelShow, message, header,  onSubmit, result, showResult, showscore, updateFlag,headerMessage,setOnReload,setWindowSwitch} = props;

  const handleClose = () => {
    // setOnReload(false)
    if(header === 'Violation of Rule !!!'){
      // window.onbeforeunload = (event) =>{
      //   // event.preventDefault();
      // }
      window.onbeforeunload = function () {
        return null;  // return null to avoid pop up
      }
      // onSubmit('111');
      //closeModal();
      window.location.reload('/dashboard')
      setModelShow(false);

    }else if(headerMessage){
      window.location.reload(false);
    }else{
      setModelShow(false);
    }
  };

  useEffect(() => {
    window.onbeforeunload = function (event) {
      event.preventDefault();
    }
  });

  return (
    <div>

      <Modal
        disableEnforceFocus = {true}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showModel}
        //onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={showModel}>
        <div className={classes.paper} style={{textAlign:"justify"}}>
            {header ?
              <div style={{ textAlign: "center" }}>
                <h3 id="transition-modal-title" style={{ color: '#6f2981', marginBottom: "30px", lineHeight: "initial" }} dangerouslySetInnerHTML={{ __html: header }} />
              </div>
              :
              headerMessage ?
                <div style={{ textAlign: "center" }}>
                  <h3 id="transition-modal-title" style={{ color: '#6f2981', marginBottom: "30px", lineHeight: "initial" }} dangerouslySetInnerHTML={{ __html: headerMessage }} />
                </div>
                : ''
            }
            {message ?
            <p id="transition-modal-description" dangerouslySetInnerHTML={{  __html: message }}>
              {/* {message} */}
            </p> : ''}
            {header === 'Violation of Rule !!!' && (showscore || !updateFlag) ? (result !== undefined && result !== null && showResult
            ? <div className="form-group" style={{textAlign:"center"}}>
                <p style={{fontWeight:'bold'}} dangerouslySetInnerHTML={{__html: result}}></p>
					      <ul className="list-inline">
                <li><a className="btn btn-blue"  style={{borderRadius:"10px"}} onClick={() => handleClose()} ><strong>Close</strong></a></li>
                </ul>
                </div> :
                <>
                   {/* <CircularProgress variant="determinate" value={50} /> */}

                 <p>
                 Your result is being processed. Please <span style={{ color: 'red' }}><strong>DO NOT </strong></span>refresh this page or close the browser, else your test result will be considered <span style={{ color: 'red' }}><strong>INVALID.</strong></span>
                 </p></>) : <div className="form-group" style={{textAlign:"center"}}>
					      <ul className="list-inline">
                <li><a className="btn btn-blue"  style={{borderRadius:"10px"}} onClick={() => handleClose()} ><strong>Close</strong></a></li>
                </ul>
              </div>}
             {/* <div className="form-group" style={{textAlign:"center"}}>
					      	<ul className="list-inline btn-list">
                <li><a className="btn btn-blue" style={{borderRadius:"10px"}} onClick={handleClose} >
                <strong>Close</strong>
            </a>
                </li>

                </ul>
                </div> */}

          </div>
        </Fade>
      </Modal>
    </div>
  );
}
