import React from 'react';
import Button from '@material-ui/core/Button';

export default function LoaderProcessing (props){
    return (
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled
        >
            <span className="mainloader">Processing...</span>
        </Button>
    );
}

function LoaderContained (props){
    return (
        <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled
        >
            <span className="mainloader">Processing...</span>
        </Button>
    );
}

const LoaderProcessingOutlined = (props) => {
    return (
        <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled
            style={{top:'-5px'}}
        >
            <span className="mainloader">Processing...</span>
        </Button>
    );
}

export {LoaderProcessingOutlined,LoaderContained}