import axios from "./axiosConfig";

const saveToMongoFunction = async (
  examId,
  examDetail,
  setWindowSwitch,
  setTestData,
  windowSwitchCount,
  testData,
  statusOnFinish,
  setisMongoUpdated
) => {
  let remainingTime = window.localStorage.getItem("remainingTime");

  if (statusOnFinish) {
    examDetail[0].status = statusOnFinish;
  } else {
    examDetail[0].status = "001";
  }

  examDetail[0].time_remaining = remainingTime;
  if (examDetail[0].webproctoringcount - windowSwitchCount >= 0) {
    examDetail[0].webproctoringcount -= windowSwitchCount;
  } else {
    examDetail[0].webproctoringcount = 0;
  }
  if (examDetail[0].defaultZeroProctorCheck != true) {
    if (examDetail[0].webproctoringcount == 0) {
      examDetail[0].webproctoringzero = true;
    }
  }

  let stringifyExamDetail = JSON.stringify(examDetail);
  let encodedExamDetail = encodeURIComponent(btoa(encodeURIComponent(stringifyExamDetail)));

  let stringifyQuesData = JSON.stringify(testData);
  let encodedQuesData = encodeURIComponent(btoa(encodeURIComponent(stringifyQuesData)));
  try {
    let result = await axios.post("/test/answeredQuestions", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      examDetail: encodedExamDetail,
      id: examDetail[0].id,
      quesData: encodedQuesData,
    });
    if (setisMongoUpdated != undefined) {
      setisMongoUpdated(true);
    }
    if (!statusOnFinish) {
      setWindowSwitch(0);
    }

    let decode = decodeURIComponent(atob(decodeURIComponent(result.data.examData)));
    let stringifyDecode = JSON.stringify(decode);
    let parseExamData = JSON.parse(stringifyDecode);
    let otherExamDetail = JSON.parse(parseExamData).examDetail;

    let otherTestData = JSON.parse(parseExamData).quesData;
    setTestData({ quesData: otherTestData, examDetail: otherExamDetail });
    window.localStorage.removeItem(examId);
    window.localStorage.removeItem("remainingTime");
    return true;
  } catch (error) {
    console.log("saveToMongoFunction error:", error);
    return false;
  }
};
export default saveToMongoFunction;
