import React, { Component } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const LoadingSkeletonGroupWise = (props) => {
  return (
      <section className="loading inner">
        <SkeletonTheme
          className="skeleton"
          color="#cacaca91"
          highlightColor="#cacaca91"
        >
          <header style={{display:'inline-flex'}}>
            <Skeleton width={165} height={40} style={{marginRight:'15px'}} />
            <Skeleton width={165} height={40} style={{marginRight:'15px'}} />
            <Skeleton width={165} height={40} style={{marginRight:'15px'}} />
          </header>
        </SkeletonTheme>
      </section>
    );
}

const LoadingSkeletonTableWise = (props) => {
  return (
      <section className="loading inner">
        <SkeletonTheme
          className="skeleton"
          color="#cacaca91"
          highlightColor="#cacaca91"
        >
            <Skeleton height={45} />
            <Skeleton height={200} />
        </SkeletonTheme>
      </section>
    );
}

export {LoadingSkeletonGroupWise,LoadingSkeletonTableWise}