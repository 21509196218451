import React from 'react';
import RecordRTC from 'recordrtc';
// import Paper from '@material-ui/core/Paper';

import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';

import axios from "../../../utils/axiosConfig";
// import Cookies from 'js-cookie';
import FormData from 'form-data';
import { WEB_URL } from '../../../utils/constants';

class CameraRecorder extends React.Component {
constructor(props) {
super(props);
this.state = { recordVideo: null, isRecording: false, blobUrl: '', adminToken: '' };
this.requestUserMedia = this.requestUserMedia.bind(this);
this.startRecord = this.startRecord.bind(this);
this.stopRecord = this.stopRecord.bind(this);
this.getUserMedia = this.getUserMedia.bind(this);
}
requestUserMedia() {
this.getUserMedia(stream => {
this.setState({ src: window.URL.createObjectURL(stream) });
});
}
startRecord() {
this.getUserMedia(stream => {
this.state.recordVideo = RecordRTC(stream, { type: 'audio' });
this.state.recordVideo.startRecording();
axios({
    method: 'POST',
    url: `${WEB_URL}admin/ws/integration/authenticate`,
    headers: {
        'X-Username': 'admin@demo.com',
        'X-Password': 'admin@2020'
    }
}).then(response => {
    
    this.setState({adminToken: response.data.data.tokenId});
}).catch(error => {
    
})
});
this.setState({isRecording: true})
}
stopRecord() {
this.state.recordVideo.stopRecording(() => {
//this.state.recordVideo.save();


this.setState({isRecording: false, blobUrl: URL.createObjectURL(this.state.recordVideo.getBlob())});
var formData = new FormData();
formData.append('file', this.state.recordVideo.getBlob(), 'exampleTemp.wav');

axios({
    method: 'POST',
    url: `${WEB_URL}admin/ws/integration/v3/uf`,
    // url: 'http://192.168.31.35:3006/admin/ws/integration/v3/uf',
    data: formData,
    headers: {
        'X-Auth-Token': this.state.adminToken,
        'Content-Type': 'multipart/form-data'
    }
}).then(response => {
    
}).catch(error => {
    
})
});
}
getUserMedia(callback) {
navigator.getUserMedia({ audio: true, video: false }, callback, error => alert(JSON.stringify(error)));
}
render() {
return (
    <>

<span className="question-num">Q .{this.props.questionNo}</span> 
            <span dangerouslySetInnerHTML={{__html: this.props.testData[this.props.questionNo -1].qtext}} />
            <br />
            <br />
            {
                !this.state.isRecording 
                ?
                <>
                    <p>Click below button to stop recording</p>
                    <PlayCircleFilledIcon onClick={() => this.startRecord()} />
                </>
                :
                <>
                    <p>Click below button to start recording</p>
                    <PauseCircleFilledIcon onClick={() => this.stopRecord()} />
                </>
            }
            <audio src={this.state.blobUrl} controls="controls" />
        </>
        
// <div>
// <button onClick={this.startRecord}>Start Record</button>
// <button onClick={this.stopRecord}>Stop Record</button>
// </div>
)
}
}
export default CameraRecorder;