import React from 'react';
import * as faceapi from 'face-api.js';
 
export default (props)=> {

const {data, setData} = props;

        React.useEffect(() => {
            const video = document.getElementById('video');
            
            if(video != null) {
                Promise.all([
                    faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
                    faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
                    faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
                    faceapi.nets.faceExpressionNet.loadFromUri('/models'),
                    faceapi.nets.ageGenderNet.loadFromUri('/models')
                    ]).then(startVideo)
            
                    function startVideo() {
                        

                        navigator.mediaDevices.getUserMedia({ video: true }).then(function(stream) {
                            //video.src = window.URL.createObjectURL(stream);
                            video.srcObject = stream;
                           // video.play();
                        });
                    // navigator.getUserMedia(
                    //     { video: {} },
                    //     stream => video.srcObject = stream,
                    //     err => 
                        
                    // )
                    }
            
                    video.addEventListener('play', () => {
                        let detectArray=[];
                     
                        setInterval(async () => {
                            const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions()
                           /*  const resizedDetections = faceapi.resizeResults(detections, displaySize)
                            canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)
                           // faceapi.draw.drawDetections(canvas, resizedDetections)
                            faceapi.draw.drawFaceLandmarks(canvas, resizedDetections)
                            faceapi.draw.drawFaceExpressions(canvas, resizedDetections) */
                            for(var i = 0; i < detections.length; i++){
                            var item = detections[i];
                           // detections.map(item => {
                                detectArray.push(item);
                                 
                                setData(data.push(item));
                                 
                             // })
                            }
                          }, 100)
                           
    
                        })
            }
        }, [document.getElementById('video')])
        
    return(
     <div>
         <video id="video" width="500" height="300"  autoPlay muted preload='auto' 
        //  style={{ margin: 0,
        //     padding: 0,
        //     display: 'flex',
        //     justifyContent: 'center',
        //     alignItems: 'center'}}
            ></video>
         <canvas id="canvas"  style={{height:"300px", width:"500px",left:"35.3%",position:"absolute", top:'14.3%'}}  />
     </div>   
    )};